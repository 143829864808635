import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Prompt } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import { Typography } from "@mui/material";
import { OrderFeatureResource } from "../../../types/order";
import Section from "../../../components/Section";
import { useLoginContext } from "../../../providers/LoginContextProvider";
import FormLabel from "../../../components/FormInputs/FormLabel";
import StaffNotificationPolicySelectorForm, { StaffNotificationPolicyForm } from "../../../components/StaffNotificationPolicySelectorForm";
import TimeForm from "../../../components/FormInputs/TimeForm";
import { Alert, FormChangedAlertModal } from "../../../components/Modal/FormChangedAlertModal";
import { useBooleanState, useMobileFlag } from "../../../lib/hooks";

export type CommonAutoSettingsFormType = {
  auto_pause_enabled: boolean;
  auto_pause_restart_enabled: boolean;
  auto_pause_minutes_threshold: number | null,
  auto_pause_num_of_orders_threshold: number | null,
  auto_pause_restart_minutes: number | null,
  auto_pause_restart_days: number | null,
  auto_pause_restart_time: number | null,
  enabled_auto_pause_notification: boolean,
  enabled_auto_restart_notification: boolean,
  auto_pause_staff_notification_policies: StaffNotificationPolicyForm[];
  auto_restart_staff_notification_policies: StaffNotificationPolicyForm[];
};

type Props = {
  orderFeature: OrderFeatureResource
  disabled: boolean;
  onSubmit: (params: Partial<OrderFeatureResource>) => void;
};

const CommonAutoSettingsForm = ({
  orderFeature,
  disabled,
  onSubmit
}: Props): JSX.Element => {
  const defaultValues = generateDefaultValues(orderFeature);
  const { control, handleSubmit, formState, reset, watch, getValues } = useForm<CommonAutoSettingsFormType>({ defaultValues });
  const [startTiming, setStartTiming] = useState(defaultValues.auto_pause_restart_days !== null ? "later" : "today");
  const { isDirty } = formState;
  const { currentStore } = useLoginContext();
  const watchValues = watch();
  const [isOpenModal, openModal, closeModal] = useBooleanState(false);
  const [finalData, setFinalData] = useState<CommonAutoSettingsFormType | null>(null);
  const [changedProperties, setChangedProperties] = useState<string[]>([]);
  const isMobile = useMobileFlag();

  useEffect(() => {
    const newDefaultValues = generateDefaultValues(orderFeature);
    reset(newDefaultValues);

    setStartTiming(newDefaultValues.auto_pause_restart_days !== null ? "later" : "today");
  }, [orderFeature, reset]);

  const onAutoSettingsSubmit = () => {
    const currentData = getValues();
    if (startTiming === "later") {
      currentData.auto_pause_restart_minutes = null;
    } else {
      currentData.auto_pause_restart_days = null;
      currentData.auto_pause_restart_time = null;
    }

    const newChangedProperties: string[] = [];
    if (defaultValues.auto_pause_enabled && !watchValues.auto_pause_enabled) {
      newChangedProperties.push("自動停止設定");
    }
    if (defaultValues.auto_pause_restart_enabled && !watchValues.auto_pause_restart_enabled) {
      newChangedProperties.push("自動再開設定");
    }
    if (newChangedProperties.length > 0) {
      setChangedProperties(newChangedProperties);
      setFinalData(currentData);
      openModal();
    } else {
      onSubmit(currentData);
    }
  };

  const handleModalSubmit = () => {
    if (finalData) {
      onSubmit(finalData);
    }
    closeModal();
  };

  return (
    <>
      <Section title="注文受付の自動停止">
        <Controller
          control={control}
          name="auto_pause_enabled"
          render={({ onChange, value }) => (
            <FormControlLabel
              label="注文受付の自動停止"
              sx={{ mb: 2 }}
              control={<Switch checked={value} onChange={(e) => onChange(e.target.checked)}/>}
            />
          )}
        />
        <FormLabel label="注文自動停止設定" />
        <Box
          display="flex"
          gap={1}
          alignItems={isMobile ? "start" : "center"}
          flexDirection={isMobile ? "column" : "row"}
          marginBottom={3}>
          <StyledInputTextContent>
            <Controller
              control={control}
              name="auto_pause_minutes_threshold"
              render={({ onChange, value }) => (
                <OutlinedInput
                  type="number"
                  value={value}
                  placeholder="30"
                  onChange={({ target }) => onChange(Number(target.value))}
                  sx={{ width: "100px" }}
                  disabled={!watchValues.auto_pause_enabled}
                />
              )}
            />
            分間に
          </StyledInputTextContent>
          <StyledInputTextContent>
            <Controller
              control={control}
              name="auto_pause_num_of_orders_threshold"
              render={({ onChange, value }) => (
                <OutlinedInput
                  type="number"
                  value={value}
                  placeholder="10"
                  onChange={({ target }) => onChange(Number(target.value))}
                  sx={{ width: "100px" }}
                  disabled={!watchValues.auto_pause_enabled}
                />
              )}
            />
            件の注文で停止
          </StyledInputTextContent>
        </Box>

        <FormLabel label="通知設定" />
          <Controller
            control={control}
            name="enabled_auto_pause_notification"
            render={({ onChange, value }) => (
              <FormControlLabel
                label="自動停止時に通知を受け取る"
                disabled={!watchValues.auto_pause_enabled}
                sx={{ mb: 1 }}
                control={<Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} />}
              />
            )}
          />
        {watchValues.enabled_auto_pause_notification && (
          <Controller
            control={control}
            name="auto_pause_staff_notification_policies"
            render={({ onChange, value }) => (
              <StaffNotificationPolicySelectorForm
                storeId={currentStore.id}
                policies={value}
                onChange={(policies) => onChange(policies)}
                tableProps={{
                  sx: {
                    width: { mobile: "100vw", tablet: "100%" },
                    ml: { mobile: -2, tablet: 0 }
                  }
                }}
                disabled={!watchValues.auto_pause_enabled}
              />
            )}
          />
        )}
      </Section>

      <Section title="注文受付の自動再開">
        <Controller
          control={control}
          name="auto_pause_restart_enabled"
          render={({ onChange, value }) => (
            <FormControlLabel
              label="注文受付の自動再開"
              sx={{ mb: 2 }}
              control={<Switch checked={value} onChange={(e) => onChange(e.target.checked)} />}
            />
          )}
        />
        <FormLabel label="注文自動再開設定" />
        <Box display="flex" alignItems="center" mb={1}>
          <RadioGroup
            value={startTiming}
            onChange={(event) => setStartTiming(event.target.value)}
            row
          >
            <FormControlLabel
              value="today"
              control={<Radio />}
              label="停止されてから当日に再開する"
              disabled={!watchValues.auto_pause_restart_enabled}
            />
            <FormControlLabel
              value="later"
              control={<Radio />}
              label="停止されてから翌日以降に再開する"
              disabled={!watchValues.auto_pause_restart_enabled}
            />
          </RadioGroup>
        </Box>
        {startTiming === "today" && (
          <Box marginBottom={3}>
            <StyledInputTextContent>
              停止されてから
              <Controller
                control={control}
                name="auto_pause_restart_minutes"
                render={({ onChange, value }) => (
                  <OutlinedInput
                    type="number"
                    value={value}
                    placeholder="60"
                    onChange={({ target }) => onChange(Number(target.value))}
                    sx={{ width: "100px" }}
                    disabled={!watchValues.auto_pause_restart_enabled}
                  />
                )}
              />
              分後に再開
            </StyledInputTextContent>
          </Box>
        )}
        {startTiming === "later" && (
          <Box
            display="flex"
            gap={1}
            alignItems={isMobile ? "start" : "center"}
            flexDirection={isMobile ? "column" : "row"}
            marginBottom={3}>
            <StyledInputTextContent>
              停止されてから
              <Controller
                control={control}
                name="auto_pause_restart_days"
                render={({ onChange, value }) => (
                  <OutlinedInput
                    type="number"
                    value={value}
                    placeholder="1"
                    onChange={({ target }) => onChange(Number(target.value))}
                    sx={{ width: "100px" }}
                    disabled={!watchValues.auto_pause_restart_enabled}
                  />
                )}
              />
              日後の
            </StyledInputTextContent>
            <StyledInputTextContent>
              <Controller
                control={control}
                name="auto_pause_restart_time"
                render={({ onChange, value }) => (
                  <TimeForm
                    value={value}
                    disabled={!watchValues.auto_pause_restart_enabled}
                    onChange={({ target }) => onChange(target.value)}
                  />
                )}
              />に再開
            </StyledInputTextContent>
          </Box>
        )}
        <FormLabel label="通知設定" />
        <Controller
          control={control}
          name="enabled_auto_restart_notification"
          render={({ onChange, value }) => (
            <FormControlLabel
              label="自動再開時に通知を受け取る"
              disabled={!watchValues.auto_pause_restart_enabled}
              sx={{ mb: 1 }}
              control={<Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} />}
            />
          )}
        />
        {watchValues.enabled_auto_restart_notification && (
          <Controller
            control={control}
            name="auto_restart_staff_notification_policies"
            render={({ onChange, value }) => (
              <StaffNotificationPolicySelectorForm
                storeId={currentStore.id}
                policies={value}
                onChange={(policies) => onChange(policies)}
                tableProps={{
                  sx: {
                    width: { mobile: "100vw", tablet: "100%" },
                    ml: { mobile: -2, tablet: 0 }
                  }
                }}
                disabled={!watchValues.auto_pause_restart_enabled}
              />
            )}
          />
        )}
      </Section>

      <Box display="flex" justifyContent="center" mb={5}>
        <Button
          variant="contained"
          color="submit"
          disabled={disabled}
          sx={{ width: 300 }}
          onClick={handleSubmit(onAutoSettingsSubmit)}
        >
          変更を保存する
        </Button>
      </Box>
      <Prompt
        when={isDirty}
        message="行った変更が保存されていない可能性があります。このページを離れますか？"
      />

      <FormChangedAlertModal
        show={isOpenModal}
        title="注文受付設定を変更"
        onSubmit={handleModalSubmit}
        onCancel={closeModal}
        submitButtonLabel="変更する"
      >
        注文受付設定の内容を変更してよろしいですか？
        <Box style={{ marginTop: 16 }}>
          <Typography fontWeight="bold" mb={0.5} fontSize={14}>変更内容</Typography>
          <Typography>{changedProperties.join("、")}</Typography>
        </Box>
        <Alert severity="warning">
          <ul>
            <li>自動設定が無効になります。</li>
            <li>自動設定を有効にするには、再度設定が必要です</li>
          </ul>
        </Alert>
      </FormChangedAlertModal>
    </>
  );
};

const StyledInputTextContent = styled(Box)(({ theme }) => ({
  gap: theme.spacing(1),
  display: "flex",
  alignItems: "center",
}));

const defaultNotificationSettings: StaffNotificationPolicyForm[] = [{
  staff_id: null,
  enabled_email: true,
  enabled_push_notification: true,
  include_child_store_staffs: false,
}];

const generateDefaultValues = (orderFeature: OrderFeatureResource): CommonAutoSettingsFormType => ({
  auto_pause_enabled: orderFeature.auto_pause_enabled,
  auto_pause_restart_enabled: orderFeature.auto_pause_restart_enabled,
  auto_pause_minutes_threshold: orderFeature.auto_pause_minutes_threshold,
  auto_pause_num_of_orders_threshold: orderFeature.auto_pause_num_of_orders_threshold,
  auto_pause_restart_minutes: orderFeature.auto_pause_restart_minutes,
  auto_pause_restart_days: orderFeature.auto_pause_restart_days,
  auto_pause_restart_time: orderFeature.auto_pause_restart_time,
  enabled_auto_pause_notification: orderFeature.enabled_auto_pause_notification,
  enabled_auto_restart_notification: orderFeature.enabled_auto_restart_notification,
  auto_pause_staff_notification_policies: orderFeature.auto_pause_staff_notification_policies || defaultNotificationSettings,
  auto_restart_staff_notification_policies: orderFeature.auto_restart_staff_notification_policies || defaultNotificationSettings,
});

export default CommonAutoSettingsForm;
