import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { withRouter, Prompt } from "react-router";
import { Grid, Row, Col, Form, HelpBlock } from "react-bootstrap";
import { cloneDeep } from "lodash";
import { useLoginContext } from "../../providers/LoginContextProvider";
import { Card } from "../../components/Card/Card";
import FormInputs from "../../components/FormInputs/FormInputs";
import CustomButton from "../../components/CustomButton/CustomButton";
import LoadingButton from "../../components/CustomButton/LoadingButton";
import ImageForm from "../../components/FormInputs/ImageForm";
import SelectorForm from "../../components/FormInputs/SelectorForm";
import SwitchForm from "../../components/FormInputs/SwitchForm";
import OptionListTable from "./parts/OptionListTable";
import MenuFormPublishSettingCard from "./parts/MenuFormPublishSettingCard";
import MenuEntityContainer, { MenuInterface, } from "../../containers/entities/MenuEntityContainer";
import { useApi, useBooleanState, useQuery, useGetApiCall } from "../../lib/hooks";
import { MenuStatus } from "../../types/menu";
import TargetUserForm from "../../components/TargetUser";
import { validateTargetUserCondition } from "../../components/TargetUser/utils";
import { useTargetUserConditionResourceConverter, useTargetUserConditionEffect, DefaultTargetUserCondition } from "../../components/TargetUser/hooks";
import { useNotification } from "../../providers/NotificationProvider";
import { FormChangedAlertModal, ModalContentFormChangedList } from "../../components/Modal/FormChangedAlertModal";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/system";
import { OrderType } from "../../types/order";

type MenuParam = Partial<MenuInterface>;
type MenuFormLocation = { function?: "order" | "ec"; menu: MenuInterface; };

const FieldLabels = {
  category: "カテゴリー",
  image_url: "メニュー画像",
  title: "メニュー名",
  body: "説明文",
  price: "価格",
  priority: "表示の優先順位",
  discount_enabled: "割引設定",
  discount_price: "割引後の金額",
  orderable_quantity_by_user: "1ユーザーあたりの注文数制限",
  orderable_quantity_per_order: "1回の注文での注文数制限",
};

const orderFunctionToJp = (func): string => {
  if (func === "takeout") return "テイクアウト";
  if (func === "delivery") return "デリバリー";
  if (func === "preorder") return "プレオーダー";
  return "";
};

const MenuFormViewWrapper = () => {
  const { response, loaded } = useGetApiCall("/menu_categories");
  const menuCategoryResponse = response?.menu_categories || [];
  return loaded ? (
    <MenuFormView menuCategories={menuCategoryResponse} />
  ) : (
    <StyledLoadingContainer>
      <CircularProgress />
    </StyledLoadingContainer>
  );
};

const MenuFormView = ({ menuCategories }): JSX.Element => {
  const history = useHistory();
  const location = useLocation<MenuFormLocation>();
  const { showSuccessNotification, showErrorNotification } = useNotification();
  const convertToResource = useTargetUserConditionResourceConverter();
  const menuEntityContainer = MenuEntityContainer.useContainer();
  const { getMenu } = menuEntityContainer.logic;
  const { hasFunction } = useLoginContext();
  const activeOrderFunctions: OrderType[] = [];
  if (hasFunction("takeout")) activeOrderFunctions.push("takeout");
  if (hasFunction("delivery")) activeOrderFunctions.push("delivery");
  if (hasFunction("preorder")) activeOrderFunctions.push("preorder");
  const editMode = !!location.state?.menu;
  const selectedFunction = location.state?.function;
  const initialMenuValue = location.state?.menu;
  const [menu, setMenu] = useState<MenuInterface>(initialMenuValue);

  const defaultValues = {
    menu: {
      status: menu?.status ?? "draft",
      title: menu?.title ?? "",
      body: menu?.body ?? "",
      price: menu?.price ?? 0,
      category: menu?.category ?? menuCategories[0].id,
      priority: menu?.priority ?? null,
      image_url: menu?.image_url ?? null,
      orderable_quantity_by_user: menu?.orderable_quantity_by_user ?? null,
      orderable_quantity_per_order: menu?.orderable_quantity_per_order ?? null,
      discount_enabled: menu?.discount_enabled ?? false,
      discount_price: menu?.discount_price ?? null,
    },
    target_user_condition: DefaultTargetUserCondition
  };
  const methods = useForm({ defaultValues });

  const {
    register,
    unregister,
    errors,
    watch,
    setValue,
    formState,
    control,
    reset,
    getValues,
    handleSubmit
  } = methods;

  const query = useQuery();
  const userGroupIds = query.get("user_group_ids");
  const userGroupCollectionId = query.get("user_group_collection_id");
  const defaultTargetUserConditionFromQuery = editMode ? null : {
    user_ids: [],
    user_group_ids: userGroupIds?.split(",").map((v) => Number(v)) || [],
    user_group_collection_id: userGroupCollectionId ? Number(userGroupCollectionId) : null
  };
  const [defaultTargetGroup, setDefaultTargetGroup] = useState("");
  const loadingTargetUserCondition = useTargetUserConditionEffect(
    defaultTargetUserConditionFromQuery || menu?.target_user_condition || null,
    (targetUserCondition) => {
      setValue("target_user_condition", targetUserCondition);
      setDefaultTargetGroup(targetUserCondition.targetGroup);
    }
  );

  const { isDirty, dirtyFields } = formState;

  const watchStatus = watch("menu.status");
  const watchDiscountEnabled = watch("menu.discount_enabled");
  const watchImage = watch("menu.image_url");
  const watchTargetUserCondition = watch("target_user_condition");

  const menuTypeOptions: { label: string; value: string }[] =
    selectedFunction === "order"
      ? activeOrderFunctions.map((func) => ({
          label: `${orderFunctionToJp(func)}`,
          value: func,
        }))
      : [
          {
            label: selectedFunction ? "オンラインショップ用" : "店内メニュー用",
            value: selectedFunction || "display",
          },
        ];

  const cardTitleMenuType = (): string => {
    switch (selectedFunction) {
      case "order": {
        return "モバイルオーダー用メニュー";
      }
      case "ec": {
        return "オンラインショップ用メニュー";
      }
      default: {
        return "店内用メニュー";
      }
    }
  };

  const processFormData = async (data) => {
    const processedData = cloneDeep(data.menu);

    if (processedData.image_url !== menu?.image_url) {
      processedData.image = processedData.image_url;
    }

    if (editMode) {
      delete processedData.status;
    }

    // 空文字対策 && 値が全て文字列になってる対策
    processedData.price = processedData.price ? Number(processedData.price) : null;
    processedData.priority = processedData.priority ? Number(processedData.priority) : null;

    processedData.target_user_condition = await convertToResource(data.target_user_condition);

    return processedData;
  };

  const { api, loading } = useApi();

  const createMenu = async () => {
    const values = getValues();
    const formData = await processFormData(values);
    const res = await api.post("/menus", { menu: formData });
    if (res !== null) {
      showSuccessNotification("メニューを作成しました。");
      reset();
      history.goBack();
    }
  };

  const deleteMenu = async () => {
    const res = await api.delete(`/menus/${menu.id}`);
    if (res !== null) {
      showSuccessNotification("メニューを削除しました。");
      reset();
      history.goBack();
    }
  };

  const updateMenu = async (params: MenuParam) => {
    if (!menu) {
      showErrorNotification("存在しないメニューです。");
      return;
    }
    const res = await api.put(`/menus/${menu.id}`, { menu: params });
    if (res !== null) {
      showSuccessNotification("更新しました。");
      reset();
      history.goBack();
    }
  };

  const updateMenuStatus = async (status: MenuStatus) => {
    const params = { status };
    await updateMenu(params);
  };
  const handleUpdateButton = async () => {
    const values = getValues();
    const formData = await processFormData(values);
    await updateMenu(formData);
  };

  useEffect(() => {
    register("menu.image_url");
    register("target_user_condition", { validate: validateTargetUserCondition });

    return () => {
      unregister("menu.image_url");
      unregister("target_user_condition");
    };
  }, [register, unregister]);

  const menuId = initialMenuValue?.id;
  useEffect(() => {
    if (editMode) {
      getMenu(menuId).then(setMenu);
    }
  }, [editMode, getMenu, menuId]);

  const onError = () => {
    showErrorNotification("値が正しく入力されていません。");
  };

  const changedLabels = Object.entries(FieldLabels)
    .map(([key, label]) => (dirtyFields.menu?.[key]) ? label : "")
    .filter(v => v);
  if (watchTargetUserCondition.targetGroup !== (defaultTargetGroup || "all")) {
    changedLabels.push("配信対象者");
  }

  const [isOpenSubmitModal, openSubmitModal, closeSubmitModal] = useBooleanState(false);
  const [isOpenDeleteModal, openDeleteModal, closeDeleteModal] = useBooleanState(false);

  return (
    <>
      <FormProvider {...methods}>
        <Grid fluid className="content">
          <Form>
            {editMode && (
              <Row data-cy="menu-form-view-publish-setting-card-top">
                <Col md={10}>
                  <MenuFormPublishSettingCard
                    disabled={loading}
                    editMode={editMode}
                    menuStatus={menu?.status}
                    onChangeStatus={updateMenuStatus}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col md={10} data-cy="menu-form-view-card">
                <Card
                  title={cardTitleMenuType() + (editMode ? "編集" : "作成")}
                  content={
                    <>
                      {menuTypeOptions.length > 1 ? (
                        <SelectorForm
                          data-cy="menu-form-view-menu-type-select"
                          name="menu.menu_type"
                          label="用途"
                          width={240}
                          options={menuTypeOptions}
                          inputRef={register}
                          disabled={editMode}
                          value={menu?.menu_type}
                        />
                      ) : (
                        <input
                          data-cy="menu-form-view-menu-type-hidden"
                          name="menu.menu_type"
                          value={menuTypeOptions[0]?.value}
                          type="hidden"
                          ref={register}
                        />
                      )}
                      <div style={{display: "flex", alignItems: "center"}}>
                        <SelectorForm
                          data-cy="menu-form-view-category-select"
                          name="menu.category"
                          label="カテゴリー"
                          width={200}
                          options={menuCategories.map((category) => ({
                            label: category.category_label,
                            value: category.id,
                          }))}
                          inputRef={register}
                          showChangeIndicator={editMode && Boolean(dirtyFields.menu?.category)}
                        />
                      </div>

                      <ImageForm
                        label="メニュー画像"
                        value={watchImage}
                        canDelete
                        onChange={(img) => {
                          setValue("menu.image_url", img, { shouldDirty: true });
                        }}
                        deleteImage={() => {
                          setValue("menu.image_url", null, { shouldDirty: true });
                        }}
                        validationMessage={errors?.menu?.image_url?.message}
                        showChangeIndicator={editMode && Boolean(dirtyFields.menu?.image_url)}
                      />
                      <HelpBlock className="text-muted">
                        画像は縦横比16:9で表示されます。
                      </HelpBlock>
                      <FormInputs
                        properties={[
                          {
                            "data-cy": "menu-form-view-menu-title",
                            name: "menu.title",
                            label: "メニュー名",
                            ncol: "col-md-9",
                            type: "text",
                            bsClass: "form-control",
                            inputRef: register({
                              required: "メニュー名を入力してください。",
                            }),
                            validationMessage: errors?.menu?.title?.message,
                            showChangeIndicator: (editMode && Boolean(dirtyFields.menu?.title))
                          },
                          {
                            "data-cy": "menu-form-view-menu-body",
                            name: "menu.body",
                            label: "説明文",
                            ncol: "col-md-12",
                            rows: "6",
                            componentClass: "textarea",
                            bsClass: "form-control",
                            inputRef: register,
                            validationMessage: errors?.menu?.body?.message,
                            showChangeIndicator: editMode && Boolean(dirtyFields.menu?.body)
                          },
                          {
                            "data-cy": "menu-form-view-menu-price",
                            name: "menu.price",
                            label: "価格",
                            unit: "円",
                            ncol: "col-md-3 col-sm-3 col-xs-8",
                            type: "number",
                            bsClass: "form-control",
                            inputRef: register({
                              required: "価格を入力してください。",
                              valueAsNumber: true,
                              min: {
                                value: 0,
                                message: "0 円以上の価格を設定してください。",
                              },
                            }),
                            validationMessage: errors?.menu?.price?.message,
                            showChangeIndicator: editMode && Boolean(dirtyFields.menu?.price)
                          },
                          Boolean(selectedFunction) && {
                            "data-cy": "menu-form-view-menu-orderable-count-by-user",
                            name: "menu.orderable_quantity_by_user",
                            label: "1ユーザーあたりの注文数制限",
                            unit: "個まで",
                            attachment: "任意",
                            ncol: "col-md-4 col-sm-4 col-xs-8",
                            type: "number",
                            bsClass: "form-control",
                            inputRef: register({
                              valueAsNumber: true,
                              min: {
                                value: 1,
                                message: "1 以上の数字を設定してください。",
                              },
                            }),
                            validationMessage: errors?.menu?.orderable_quantity_by_user?.message,
                            showChangeIndicator: editMode && Boolean(dirtyFields.menu?.orderable_quantity_by_user)
                          },
                          Boolean(selectedFunction) && {
                            name: "menu.orderable_quantity_per_order",
                            label: "1回の注文での注文数制限",
                            unit: "個まで",
                            attachment: "任意",
                            ncol: "col-md-4 col-sm-4 col-xs-8",
                            type: "number",
                            bsClass: "form-control",
                            inputRef: register({
                              valueAsNumber: true,
                              min: {
                                value: 1,
                                message: "1 以上の数字を設定してください。",
                              },
                            }),
                            validationMessage: errors?.menu?.orderable_quantity_per_order?.message,
                            showChangeIndicator: editMode && Boolean(dirtyFields.menu?.orderable_quantity_per_order)
                          },
                        ].filter((v) => v)}
                      />

                      <FormInputs
                        properties={[
                          {
                            "data-cy": "menu-form-view-menu-priority",
                            name: "menu.priority",
                            label: "表示の優先順位",
                            ncol: "col-md-3 col-sm-3 col-xs-8",
                            type: "number",
                            attachment: "任意",
                            inputRef: register({ valueAsNumber: true }),
                            showChangeIndicator: editMode && Boolean(dirtyFields.menu?.priority)
                          },
                        ]}
                      />

                      <Controller
                        control={control}
                        name="menu.discount_enabled"
                        render={({ value, onChange }) => (
                          <SwitchForm
                            data-cy="menu-form-view-menu-discount-enabled"
                            label="割引設定"
                            checked={value}
                            onChange={(v) => onChange(v)}
                            showChangeIndicator={editMode && Boolean(dirtyFields.menu?.discount_enabled)}
                          />
                        )}
                      />

                      <FormInputs
                        data-cy="menu-form-view-form-inputs2"
                        properties={[
                          {
                            "data-cy": "menu-form-view-menu-discount-price",
                            label: "割引後の金額",
                            name: "menu.discount_price",
                            unit: "円",
                            ncol: "col-md-3 col-sm-3 col-xs-8",
                            type: "number",
                            bsClass: "form-control",
                            disabled: !watchDiscountEnabled,
                            inputRef: register({
                              required: watchDiscountEnabled ? "価格を設定してください。" : false,
                              valueAsNumber: true,
                              min: {
                                value: 0,
                                message: "0 円以上の価格を設定してください。",
                              },
                            }),
                            validationMessage: errors.menu?.discount_price?.message,
                            showChangeIndicator: editMode &&  Boolean(dirtyFields.menu?.discount_price)
                          },
                        ]}
                      />
                    </>
                  }
                />
                <Card
                  title="配信対象者"
                  content={
                    <TargetUserForm
                      errorMessage={errors?.target_user_condition?.["message"]}
                      targetUserCondition={watchTargetUserCondition}
                      onChangeTargetUserCondition={(targetUserCondition) => {
                        setValue("target_user_condition", targetUserCondition, { shouldDirty: true });
                      }}
                      showChangeIndicator={watchTargetUserCondition.targetGroup !== (defaultTargetGroup || "all" )}
                    />
                  }
                />
              </Col>
            </Row>

            {!editMode && (
              <Row data-cy="menu-form-view-publish-setting-card-bottom">
                <Col md={10}>
                  <MenuFormPublishSettingCard
                    disabled={loading}
                    editMode={editMode}
                    menuStatus={menu?.status}
                    onChangeStatus={updateMenuStatus}
                  />
                </Col>
              </Row>
            )}

            <Row>
              <Col md={10}>
                <Row style={{ marginBottom: 30 }}>
                  <Col xs={6} md={5} mdOffset={1}>
                    <CustomButton block fill onClick={history.goBack}>
                      <b>もどる</b>
                    </CustomButton>
                  </Col>
                  <Col xs={6} md={5}>
                    <LoadingButton
                      data-cy="menu-form-button-group-submit-button"
                      color="info"
                      label={editMode ? "編集する" : "作成する"}
                      block
                      fill
                      onClick={handleSubmit(editMode ? openSubmitModal : createMenu, onError)}
                      loading={loading}
                      disabled={loadingTargetUserCondition || !isDirty}
                    />
                  </Col>
                </Row>

                {editMode && (
                  <Row>
                    <Col xs={8} xsOffset={2} md={6} mdOffset={3}>
                      <CustomButton
                        bsStyle="danger"
                        block
                        simple
                        onClick={openDeleteModal}
                        style={{ fontWeight: "bold" }}
                        disabled={loading || loadingTargetUserCondition}
                      >
                        削除する
                      </CustomButton>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>

            {editMode && (
              <Row>
                <Col md={10}>
                  <OptionListTable
                    options={menu.options}
                    menuId={menu.id}
                    setMenu={setMenu}
                    selectedFunction={selectedFunction}
                  />
                </Col>
              </Row>
            )}
          </Form>
        </Grid>
      </FormProvider>

      <Prompt
        when={isDirty}
        message="行った変更が保存されていない可能性があります。このページを離れますか？"
      />

      <FormChangedAlertModal
        title="メニュー設定の更新"
        show={isOpenSubmitModal}
        onSubmit={handleUpdateButton}
        onCancel={closeSubmitModal}
      >
        メニューの内容を更新してよろしいですか？
        <ModalContentFormChangedList
          changedProperties={changedLabels}
        />
      </FormChangedAlertModal>

      <FormChangedAlertModal
        title="メニューの削除"
        show={isOpenDeleteModal}
        onSubmit={deleteMenu}
        onCancel={closeDeleteModal}
        displayStatus="danger"
        submitButtonLabel="削除する"
      >
        メニューを削除してよろしいですか？
      </FormChangedAlertModal>
    </>
  );
};

const StyledLoadingContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "white",
  padding: theme.spacing(10)
}));

export default withRouter(MenuFormViewWrapper);
