import React, { useEffect, useState, useCallback } from "react";
import { styled } from "@mui/material/styles";
import { Row, Col, ButtonToolbar, DropdownButton, MenuItem } from "react-bootstrap";
import { useHistory } from "react-router";
import { Pagination } from "@mui/material";
import { AutoCouponContentStatusToJpObj } from "../../../containers/entities/CouponContentEntityContainer";
import { SortOrderType } from "../../../types/common.d";
import { CouponSortByType } from "../../../types/coupon";
import { CouponContentsResponse, CouponContentBlock } from "../../../types/api/coupon.d";
import { useApi, usePcSizeFlag } from "../../../lib/hooks";
import DisablingMask from "../../../components/DisablingMask";
import { Card } from "../../../components/Card/Card";
import ContentSummaryCard from "../../../components/Card/ContentSummaryCard";
import PrivilegeCouponTable from "./PrivilegeCouponTable";
import StatusLabel from "./StatusLabel";
import { useLoginContext } from "../../../providers/LoginContextProvider";

const SortLabels = {
  read_count: {
    desc: "開封数の多い順",
    asc: "開封数の少ない順"
  },
  used_count: {
    desc: "利用数の多い順",
    asc: "利用数の少ない順"
  },
} as const;

const SortFields = [
  { sortBy: "read_count", sortOrder: "desc" },
  { sortBy: "read_count", sortOrder: "asc" },
  { sortBy: "used_count", sortOrder: "desc" },
  { sortBy: "used_count", sortOrder: "asc" },
] as const;

type FetchApiParams = Partial<{
  page: number;
  sortBy: CouponSortByType;
  sortOrder: SortOrderType;
}>;

type Props = {
  onDeleteCouponContent: (id: number) => void;
  loading: boolean;
};

const PrivilegeCouponList = ({
  onDeleteCouponContent,
  loading
}: Props): JSX.Element => {
  const history = useHistory();
  const isPcOrTablet = usePcSizeFlag();
  const limit = isPcOrTablet ? 20 : 4;
  const [sortBy, setSortBy] = useState<CouponSortByType>(SortFields[0].sortBy);
  const [sortOrder, setSortOrder] = useState<SortOrderType>(SortFields[0].sortOrder);
  const { api, response, totalPages, currentPage } = useApi<CouponContentsResponse>();
  const privilegeCouponContents = response?.coupon_contents || [];
  const { currentStore } = useLoginContext();
  const showDeliveredCount = currentStore.show_coupon_delivered_count;
  const showReadCount = currentStore.show_coupon_read_count;

  const getPrivilegeCouponContents = useCallback(async (params: FetchApiParams = {}) => {
    const page = params.page || 1;
    const sort = params.sortBy || SortFields[0].sortBy;
    const order = params.sortOrder || SortFields[0].sortOrder;

    const query = { type: "code", page, sort, order, limit };
    const res = await api.get("/coupon_contents", query);
    if (!res) return;

    setSortBy(sort);
    setSortOrder(order);
  }, [limit, api]);

  useEffect(() => {
    getPrivilegeCouponContents();
  }, [getPrivilegeCouponContents]);

  const goToPrivilegeCouponEdit = (coupon: CouponContentBlock) => {
    history.push("/admin/privilege_coupon/edit?isCode=true", {
      privilegeCoupon: {
        qrcode_url: coupon.qrcode?.image_url,
        ...coupon.qrcode,
        ...coupon.obtain_condition,
        ...coupon.coupon_content,
      },
    });
  };

  return (
    <Row>
      <Col md={12}>
        <Card
          title="特殊配信"
          rightContent={
            !isPcOrTablet && (
              <ButtonToolbar className="pull-right">
                <DropdownButton
                  title={SortLabels[sortBy][sortOrder]}
                  bsSize="small"
                  style={{ border: "none", color: "#000" }}
                  pullRight
                  id="dropdown-coupon-list"
                >
                  {SortFields.map((item) => (
                    <MenuItem onClick={() => getPrivilegeCouponContents(item)}>
                      {SortLabels[item.sortBy][item.sortOrder]}
                    </MenuItem>
                  ))}
                </DropdownButton>
              </ButtonToolbar>
            )
          }
          content={
            isPcOrTablet ? (
              <DisablingMask mask={loading}>
                <PrivilegeCouponTable
                  privilegeCouponContents={privilegeCouponContents}
                  onClickCouponContent={(coupon) => goToPrivilegeCouponEdit(coupon)}
                  onDeleteCouponContent={(id) => onDeleteCouponContent(id)}
                  sortBy={sortBy}
                  sortOrder={sortOrder}
                  onSort={(newSortBy, newSortOrder) => {
                    getPrivilegeCouponContents({
                      sortBy: newSortBy,
                      sortOrder: newSortOrder,
                    });
                  }}
                />
              </DisablingMask>
            ) : (
              privilegeCouponContents.map((item, index) => (
                <StyledContentSummaryCard
                  key={item.coupon_content.id}
                  last={privilegeCouponContents.length - 1 === index}
                  onClick={() => goToPrivilegeCouponEdit(item)}
                  imageUrl={item.coupon_content.image_url}
                  title={item.coupon_content.benefit}
                  label={
                    <StatusLabel status={item.coupon_content.status} size="small">
                      {AutoCouponContentStatusToJpObj[item.coupon_content.status]}
                    </StatusLabel>
                  }
                  {...(showDeliveredCount && { deliveredCount: item.delivered_count})}
                  {...(showReadCount && { readCount: item.read_count})}
                  {...(showReadCount && { readCountDiff: item.read_count_diff})}
                  usedCount={item.used_count}
                  usedCountDiff={item.used_count_diff}
                  salesEffect={item.sales_effect}
                  createdAt={item.coupon_content.created_at}
                  memo={item.coupon_content.memo}
                  hasAvailableArrivalCount={item.has_available_arrival_count}
                />
              ))
            )
          }
          stats={
            totalPages > 1 ? (
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={(_, value) =>
                  getPrivilegeCouponContents({
                    page: value,
                    sortBy: sortBy,
                    sortOrder: sortOrder,
                  })
                }
                sx={{ fontSize: 24, color: "black" }}
              />
            ) : null
          }
        />
      </Col>
    </Row>
  );
};

const StyledContentSummaryCard = styled(ContentSummaryCard, {
  shouldForwardProp: (prop: string) => "last" !== prop,
})<{ last: boolean }>(({ theme, last }) => ({
  padding: theme.spacing(2, 0),
  borderBottom: last ? "none" : "1px solid rgba(0, 0, 0, 0.1)",
}));

export default PrivilegeCouponList;
