import React from "react";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { Gift } from "phosphor-react";
import { RankingResource } from "../../../types/ranking";
import { useMobileFlag } from "../../../lib/hooks";
import NoItemContent from "../../../components/Utils/NoItemContent";
import CustomButton from "../../../components/CustomButton/CustomButton";

type Props = {
  ranking: RankingResource;
}
const RankingRewardListTable = ({ ranking }: Props): JSX.Element => {
  const isMobile = useMobileFlag();
  const history = useHistory();
  const rewards = ranking.rewards;

  return (
    <Paper
      square={isMobile}
      variant={isMobile ? "elevation" : "outlined"}
      sx={{ m: isMobile ? 0 : 1 }}
    >
      <Box sx={{ m: 2, mb: 10 }}>
        <CustomButton
          bsStyle="info"
          fill
          pullRight
          onClick={() => history.push(`/admin/reward/new?ranking_id=${ranking.id}`)}
        >
          <b>特典を作成する</b>
        </CustomButton>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={100}>状態</TableCell>
            <TableCell>タイトル</TableCell>
            <TableCell width={100}>対象者</TableCell>
            {false && (
              <>
                <TableCell width={100}>配信数</TableCell>
                <TableCell width={100}>到達数</TableCell>
                <TableCell width={100}>開封数</TableCell>
                <TableCell width={100}>利用数</TableCell>
                <TableCell width={200}>配信効果売上</TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rewards.map((reward) => (
            <StyledBodyRow key={reward.id}
              hover
              onClick={() =>
                history.push(`reward/edit?ranking_id=${ranking.id}`, {
                  privilegeCoupon: {
                    reward
                  },
                  start_rank: reward.start_rank,
                  end_rank: reward.end_rank
                })
              }
            >
              <TableCell >有効/無効</TableCell>
              <TableCell>{reward.title}</TableCell>
              <TableCell>{reward.start_rank === reward.end_rank ? reward.start_rank : reward.start_rank + "〜" + reward.end_rank}位</TableCell>
              {false && (
                <>
                  <TableCell width={100}>配信数</TableCell>
                  <TableCell width={100}>到達数</TableCell>
                  <TableCell width={100}>開封数</TableCell>
                  <TableCell width={100}>利用数</TableCell>
                  <TableCell width={200}>配信効果売上</TableCell>
                </>
              )}
            </StyledBodyRow>
          ))}
        </TableBody>
      </Table>
      {rewards.length === 0 && (
        <NoItemContent icon={<Gift />} label="特典がありません" />
      )}
    </Paper>
  );
};

const StyledBodyRow = styled(TableRow)(({ theme }) => ({
  cursor: "pointer",
  "& > td": {
    fontSize: 13,
    fontWeight: 700,
    padding: theme.spacing(3.5, 1)
  }
}));

export default RankingRewardListTable;
