import React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import Barcode from "react-barcode";
import { Controller, useFormContext } from "react-hook-form";
import FormHelperText from "@mui/material/FormHelperText";
import SelectorForm from "../../../components/FormInputs/SelectorForm";
import FormLabel from "../../../components/FormInputs/FormLabel";
import { BarcodeFormatTypes, CouponUsageType } from "../../../constants/coupon";
import { useMobileFlag } from "../../../lib/hooks";

type Props = {
  userContentUsageMethod: string;
  autoUseTimerMinutes: number;
  barcodeType: string;
  couponCode: string;
};

const CouponUsageMethodForm = ({
  userContentUsageMethod,
  autoUseTimerMinutes,
  barcodeType,
  couponCode,
}: Props): JSX.Element => {
  const isMobile = useMobileFlag();
  const { setValue, formState: { errors } } = useFormContext();
  const barcodeFormatType = BarcodeFormatTypes[barcodeType] || BarcodeFormatTypes.code128

  return (
    <Box>
      <Controller
        name="coupon.user_content_usage_method"
        defaultValue={userContentUsageMethod}
        render={({ onChange, value }) => (
          <SelectorForm
            label="利用方法"
            value={value}
            options={Object.entries(CouponUsageType).map(([key, label]) => ({
              value: key,
              label,
            }))}
            onSelect={({ target }) => {
              if (target.value === "barcode") {
                setValue("coupon.barcode_type", barcodeFormatType.toLowerCase());
              }
              onChange(target.value)
            }}
            width={300}
          />
        )}
      />

      {userContentUsageMethod === "barcode" && (
        <Box>
          <Box display="flex" flexDirection={isMobile ? "column": "row"} gap={4} marginBottom="20px">
            <Box width={isMobile ? "100%" : "50%"}>
              <Controller
                name="coupon.barcode_type"
                defaultValue={barcodeFormatType.toLowerCase()}
                render={({ onChange, value }) => (
                  <SelectorForm
                    label="バーコードの種類"
                    value={value}
                    options={Object.entries(BarcodeFormatTypes).map(([key, label]) => ({
                      value: key,
                      label,
                    }))}
                    style={{width: 300}}
                    onSelect={({ target }) => {
                      onChange(target.value);
                    }}
                  />
                )}
              />

              <Controller
                name="coupon.coupon_code"
                defaultValue={couponCode}
                rules={{ required: "バーコードの値を入力してください。" }}
                render={({ onChange, value }) => (
                  <Box>
                    <FormLabel label="バーコードの値" />
                    <OutlinedInput
                      value={value}
                      onChange={({ target }) => {
                        onChange(target.value);
                      }}
                      inputProps={{ maxLength: 20 }}
                      style={{width: 300}}
                      placeholder="11111111"
                    />
                    {errors.coupon?.coupon_code && (
                      <FormHelperText error>
                        {errors.coupon.coupon_code.message}
                      </FormHelperText>
                    )}
                  </Box>
                )}
              />

              <Controller
                name="coupon.auto_use_timer_minutes"
                defaultValue={autoUseTimerMinutes}
                rules={{ required: "利用後のバーコード表示時間を入力してください。" }}
                render={({ onChange, value }) => (
                  <Box mt={2}>
                    <FormLabel label="利用後のバーコード表示時間" />
                    <OutlinedInput
                      value={value}
                      onChange={({ target }) => {
                        onChange(Math.max(1, Number(target.value)));
                      }}
                      type="number"
                      placeholder="15"
                      sx={{ mr: 1 }}
                      style={{ width: 100 }}
                    />
                    分
                    {errors.coupon?.auto_use_timer_minutes && (
                      <FormHelperText error>
                        {errors.coupon.auto_use_timer_minutes.message}
                      </FormHelperText>
                    )}
                  </Box>
                )}
              />
            </Box>

            {couponCode && (
              <Box width={isMobile ? "100%" : "30%"} display="flex" flexDirection="column" alignItems="center">
                <FormLabel label="バーコードサンプル" />
                <Barcode value={couponCode} format={barcodeFormatType} width={1} height={50} />
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CouponUsageMethodForm;
