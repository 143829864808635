import React, { useState } from "react";
import { Modal, Button, FormGroup } from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import { Japanese } from "flatpickr/dist/l10n/ja";

type Props = {
  show: boolean;
  handleClose: () => void;
  handleApply: (from: string | undefined, to: string | undefined) => void;
}
const DateRangeModal = ({
  show, handleClose, handleApply
}: Props): JSX.Element => {
  const [from, setFrom] = useState<string | undefined>(undefined);
  const [to, setTo] = useState<string | undefined>(undefined);

  const onApply = () => {
    let updatedFrom = from;
    let updatedTo = to;

    if (updatedFrom && updatedTo) {
      const fromDT = new Date(updatedFrom);
      const toDT = new Date(updatedTo);
      if (fromDT > toDT) {
        [updatedFrom, updatedTo] = [updatedTo, updatedFrom];
        setFrom(updatedFrom);
        setTo(updatedTo);
      }
    }
    if (updatedFrom || updatedTo) {
      handleApply(updatedFrom, updatedTo);
    }
    handleClose();
  };

  const onReset = () => {
    setFrom(undefined);
    setTo(undefined);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>期間絞り込み</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>期間</h5>
        <FormGroup controlId="formFromDate">
          <Flatpickr
            id="formFromDate"
            value={from || ""}
            onChange={(date: Date[]) => {
              if (Array.isArray(date) && date.length > 0 && date[0] instanceof Date && !isNaN(date[0].getTime())) {
                setFrom(date[0].toLocaleDateString("ja-JP", { year: "numeric", month: "2-digit", day: "2-digit" }).replace(/\//g, "-"));
              }
            }}
            placeholder="いつから"
            options={{
              altInput: true,
              allowInput: true,
              locale: Japanese,
              altFormat: "Y年m月d日",
              dateFormat: "Y/m/d",
            }}
          />
        </FormGroup>
        <FormGroup controlId="formToDate">
          <Flatpickr
            id="formToDate"
            value={to || ""}
            onChange={(date: Date[]) => {
              if (Array.isArray(date) && date.length > 0 && date[0] instanceof Date && !isNaN(date[0].getTime())) {
                setTo(date[0].toLocaleDateString("ja-JP", { year: "numeric", month: "2-digit", day: "2-digit" }).replace(/\//g, "-"));
              }
            }}
            placeholder="いつまで"
            options={{
              altInput: true,
              allowInput: true,
              locale: Japanese,
              altFormat: "Y年m月d日",
              dateFormat: "Y/m/d",
            }}
          />
        </FormGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn-primary"
          style={{
            backgroundColor: "#1EC7EA",
            color: "#fff",
            border: "none",
            width: "100%",
            marginBottom: "10px",
          }}
          onClick={onApply}
        >
          この条件で絞り込む
        </Button>
        <Button
          className="btn-secondary"
          style={{
            width: "100%",
            color: "#A0A0A0",
            border: "none",
            backgroundColor: "transparent",
          }}
          onClick={onReset}
        >
          条件をリセットする
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DateRangeModal;
