import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Pagination from "@mui/material/Pagination";
import moment from "moment";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { useNotification } from "../../providers/NotificationProvider";
import { useMobileFlag, useBooleanState, useApi, useGetApiCall, useQuery } from "../../lib/hooks";
import { downloadCSVFile } from "../../lib/general";
import { OrderStatus, OrdersResponse, OrderFeatureResource, OrderResource } from "../../types/order";
import FormLabel from "../../components/FormInputs/FormLabel";
import TenantAlert from "../../components/Modal/TenantAlert";
import OrderStatusChangeModal from "./parts/OrderStatusChangeModal";
import LaptopOrderListTable from "./parts/LaptopOrderListTable";
import MobileOrderListTable from "./parts/MobileOrderListTable";
import DisablingMask from "../../components/DisablingMask";
import OrderDetailModal from "./parts/OrderDetailModal";
import OrderCancelModal from "./parts/OrderCancelModal";
import { Alert, FormChangedAlertModal } from "../../components/Modal/FormChangedAlertModal";

const TabList = ["doing", "done", "cancel"];
type TabType =  typeof TabList[number];

const StatusMap = {
  doing: ["unapproved", "receive", "ready", "wait", "in_delivery", "shipped"],
  done: ["done"],
  cancel: ["cancel"]
} as const;

const LabelMap = {
  doing: "対応中",
  done: "受け渡し済み",
  cancel: "キャンセル済み"
} as const;

const OrderManagementView = (): JSX.Element => {
  const { showSuccessNotification } = useNotification();
  const isMobile = useMobileFlag();
  const orderFeatureApi = useGetApiCall<OrderFeatureResource>("/order_features");
  const csvApi = useApi();
  const updateApi = useApi();
  const {
    orders,
    loading: fetching,
    queryParams,
    reloadOrders,
    totalPages,
    changeUrlAndFetchOrders,
    setIdToUrl,
    loaded
  } = useOrders();
  const loading = fetching || orderFeatureApi.loading || updateApi.loading;
  const orderFeature = orderFeatureApi.response;
  const [selectedOrder, setSelectedOrder] = useState<OrderResource | null>(null);
  const [csvAnchorEl, setCsvAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [isOpenStatusChangeModal, openStatusChangeModal, closeStatusChangeModal] = useBooleanState(false);
  const [orderIdForCancel, setOrderIdForCancel] = useState<number | null>(null);
  const [isOpenOrderCancelModal, openOpenOrderCancelModal, closeOpenOrderCancelModal] = useBooleanState(false);
  const dateLabel = queryParams.tab === "doing" ? "商品準備予定時間" : "完了日時";
  const showBusyStatus = orderFeature?.takeout_enabled || orderFeature?.delivery_enabled;
  const showPauseButton = orderFeature?.takeout_enabled || orderFeature?.delivery_enabled || orderFeature?.preorder_enabled;
  const [isOpenModal, openModal, closeModal] = useBooleanState(false);
  const [restartType, setRestartType] = useState<"manual" | "auto">("manual");

  const usePolling = (callback: () => void, delay: number) => {
    const savedCallback = useRef(callback);

    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
      const tick = () => savedCallback.current();
      if (delay !== null) {
        const id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
      return undefined;
    }, [delay]);
  };

  usePolling(orderFeatureApi.reload, 60000);

  const downloadOrdersCsv = async (tab?: TabType) => {
    const params = { status: tab ? StatusMap[tab] : undefined };
    const csvData = await csvApi.api.blob("/orders.csv", params);

    downloadCSVFile(csvData);
    setCsvAnchorEl(null);
  };

  const changeStatus = async (id: number, status: OrderStatus) => {
    const response = await updateApi.api.put(`/orders/${id}`, { status });
    if (response !== null) {
      showSuccessNotification(`注文のステータスを変更しました。`);
      reloadOrders();
    }
  };

  const updateBusyStatus = async (isBusy: boolean) => {
    const response = await updateApi.api.patch("/order_features", { is_busy: isBusy });
    if (response !== null) {
      showSuccessNotification("更新しました。");
      orderFeatureApi.reload();
    }
  };

  const updateOrderPaused = async (orderPaused: boolean, type?: "manual" | "auto") => {
    const response = await updateApi.api.patch("/order_features", {
      order_paused: orderPaused, 
      restart_type: type || restartType
    });
    if (response !== null) {
      showSuccessNotification("更新しました。");
      orderFeatureApi.reload();
    }
  };

  const handleOrderPaused = (orderPaused: boolean) => {
    if (orderPaused && orderFeature?.auto_pause_restart_enabled) {
      openModal();
    } else {
      updateOrderPaused(orderPaused)
    }
  };

  const handleModalSubmit = () => {
    updateOrderPaused(true, restartType)
    closeModal()
  };

  const handleClickStatusLabel = (order: OrderResource): void => {
    if (order.status === "cancel") {
      window.alert("キャンセルした注文の状態は変更できません");
    } else {
      setSelectedOrder(order);
      openStatusChangeModal();
    }
  };

  const handleClickMenu = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, order: OrderResource) => {
    setMenuAnchorEl(e.currentTarget);
    setSelectedOrder(order);
  };

  const menuHandler = (fn: () => void) => () => {
    fn();
    setMenuAnchorEl(null);
  };

  const orderCancelHandler = (id: number) => () => {
    setOrderIdForCancel(id);
    openOpenOrderCancelModal();
    setMenuAnchorEl(null);
  };

  return (
    <>
      {(orderFeature != null && showPauseButton) && (
        <Box
          bgcolor={orderFeature.order_paused ? "#A1A1AD" : "#A1E82C"}
          paddingY={1}
          paddingLeft={2}
          color="white"
          fontSize={16}
          fontWeight="bold"
        >
          {orderFeature.order_paused ? "注文受付を一時停止しています" : "注文を受け付けています"}
          {orderFeature.pause_restart_at ? `（${moment(orderFeature.pause_restart_at).format("MM/DD HH:mm")} に注文再開されます）` : null}
          {orderFeature.camel_integration_status === "active" ? "（Camel連携中）" : ""}
        </Box>
      )}
      <Box p={2} paddingBottom={0}>
        <TenantAlert functionName="オーダー" />
        <Box display="flex" alignItems="center" justifyContent="flex-start">
          {(orderFeature != null && showBusyStatus) && (
            <>
              <FormLabel label="目安時間:" style={{ marginBottom: 0 }} />
              <Select
                value={orderFeature.is_busy ? "busy" : "unbusy"}
                disabled={orderFeatureApi.loading}
                sx={{ width: 200, backgroundColor: "#fff", ml: 1 }}
                onChange={(e) => updateBusyStatus(e.target.value === "busy")}
              >
                <MenuItem value="unbusy">
                  通常: {[
                    orderFeature.takeout_enabled && `${orderFeature.order_duration}分`,
                    orderFeature.delivery_enabled && `${orderFeature.delivery_order_duration}分`
                  ].filter((v) => v).join(" / ")}
                </MenuItem>
                <MenuItem value="busy">
                  忙しい: {[
                    orderFeature.takeout_enabled && `${orderFeature.order_duration_on_busy}分`,
                    orderFeature.delivery_enabled && `${orderFeature.delivery_order_duration_on_busy}分`
                  ].filter((v) => v).join(" / ")}
                </MenuItem>
              </Select>
            </>
          )}
          {(orderFeature != null && showPauseButton) && (
              <Button
                disabled={loading}
                variant={orderFeature.order_paused ? "contained" : "outlined"}
                color={orderFeature.order_paused ? "submit" : "cancel"}
                style={{ marginLeft: "auto" }}
                onClick={() => handleOrderPaused(!orderFeature.order_paused)}
              >
                {orderFeature.order_paused ? `注文を再開する` : `注文を一時停止する`}
              </Button>
          )}
        </Box>
      </Box>

      <Paper
        variant={isMobile ? "elevation" : "outlined"}
        square={isMobile}
        sx={{ m: isMobile ? 0 : 2 }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2} p={2}>
          <Typography variant="title">注文一覧</Typography>

          {!isMobile && (
            <>
              <Button
                disabled={csvApi.loading || loading}
                variant="contained"
                color="submit"
                endIcon={<i className="ri-arrow-down-s-fill" style={{ lineHeight: "1" }} />}
                onClick={(e) => setCsvAnchorEl(e.currentTarget)}
              >
                CSV出力
              </Button>

              <Menu
                anchorEl={csvAnchorEl}
                open={Boolean(csvAnchorEl)}
                onClose={() => setCsvAnchorEl(null)}
              >
                <MenuItem onClick={() => downloadOrdersCsv("doing")}>{LabelMap.doing}</MenuItem>
                <MenuItem onClick={() => downloadOrdersCsv("done")}>{LabelMap.done}</MenuItem>
                <MenuItem onClick={() => downloadOrdersCsv("cancel")}>{LabelMap.cancel}</MenuItem>
                <MenuItem onClick={() => downloadOrdersCsv()}>すべて</MenuItem>
              </Menu>
            </>
          )}
        </Box>

        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={queryParams.tab}
          onChange={(_, tab: string) => changeUrlAndFetchOrders({ page: 1, tab })}
        >
          <Tab value="doing" label={LabelMap.doing} disabled={loading} />
          <Tab value="done" label={LabelMap.done} disabled={loading} />
          <Tab value="cancel" label={LabelMap.cancel} disabled={loading} />
        </Tabs>

        <DisablingMask mask={loading}>
          {isMobile ? (
            <MobileOrderListTable
              orders={orders}
              dateLabel={dateLabel}
              onClickOrder={(order) => setIdToUrl(order.id)}
              onClickMenu={handleClickMenu}
              onUpdateStatus={changeStatus}
            />
          ) : (
            <LaptopOrderListTable
              orders={orders}
              dateLabel={dateLabel}
              onClickOrder={(order) => setIdToUrl(order.id)}
              onClickMenu={handleClickMenu}
              onClickStatusLabel={handleClickStatusLabel}
            />
          )}

          {loaded && orders.length === 0 && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              gap={2}
              py={6}
            >
              <Box
                bgcolor="#F3F5F9"
                borderRadius={10}
                width={40}
                height={40}
                display="flex"
                alignItems="center"
                justifyContent="center"
                color="#555"
                fontSize={20}
              >
                <i className="ri-smartphone-line" />
              </Box>
              注文はありません。
            </Box>
          )}
        </DisablingMask>

        {totalPages > 1 && (
          <Pagination
            sx={{ my: 1 }}
            disabled={loading}
            count={totalPages}
            page={queryParams.page}
            onChange={(_, page) => changeUrlAndFetchOrders({ ...queryParams, page })}
          />
        )}
      </Paper>

      {selectedOrder && (
        <OrderStatusChangeModal
          order={selectedOrder}
          open={isOpenStatusChangeModal}
          onClose={closeStatusChangeModal}
          disabled={updateApi.loading}
          onSubmit={async (newStatus) => {
            await changeStatus(selectedOrder.id, newStatus);
            closeStatusChangeModal();
          }}
        />
      )}

      {selectedOrder && (
        <Menu
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={() => setMenuAnchorEl(null)}
        >
          <MenuItem onClick={() => setIdToUrl(selectedOrder.id)}>
            詳細を見る
          </MenuItem>

          {selectedOrder.status !== "cancel" && (
            <>
              <MenuItem onClick={menuHandler(openStatusChangeModal)}>状態を変更する</MenuItem>
              <MenuItem
                sx={{ color: "#FF4A55" }}
                onClick={orderCancelHandler(selectedOrder.id)}
              >
                注文をキャンセルする
              </MenuItem>
            </>
          )}
        </Menu>
      )}

      <OrderDetailModal
        orderId={queryParams.id ?? 0}
        open={Boolean(queryParams.id)}
        onClose={() => {
          setIdToUrl(undefined);
          reloadOrders();
        }}
      />

      <OrderCancelModal
        orderId={orderIdForCancel ?? 0}
        open={isOpenOrderCancelModal}
        onClose={() => {
          closeOpenOrderCancelModal();
          setOrderIdForCancel(null);
          reloadOrders();
        }}
      />

      <FormChangedAlertModal
        show={isOpenModal}
        title="注文受付を一時停止"
        onSubmit={handleModalSubmit}
        onCancel={closeModal}
        submitButtonLabel="一時停止する"
      >
        注文受付を一時停止します。よろしいですか？
        <Box style={{ marginTop: 16 }}>
          <Typography fontWeight="bold" mb={0.5} fontSize={14}>注文受付の再開方法</Typography>
          <Box display="flex" alignItems="center" mb={1}>
            <RadioGroup
              value={restartType}
              onChange={(event) => setRestartType(event.target.value as "manual" | "auto")}
              row
            >
              <FormControlLabel
                value="manual"
                control={<Radio />}
                label="手動で再開"
              />
              <FormControlLabel
                value="auto"
                control={<Radio />}
                label="自動で再開"
              />
            </RadioGroup>
          </Box>
        </Box>
        <Alert severity="warning">
          <Typography>
          「自動で再開」を選択すると、「注文自動再開設定」の内容で自動的に再開します。
          </Typography>
        </Alert>
      </FormChangedAlertModal>
    </>
  );
};

export type QueryParams = {
  id?: number;
  page: number;
  tab: TabType;
};

const useOrders = () => {
  const history = useHistory();
  const location = useLocation();
  const timerRef = useRef<NodeJS.Timer | null>(null);
  const query = useQuery();
  const tabFromQuery = query.get("tab") as TabType;
  const { api, loading, loaded, response, totalPages } = useApi<OrdersResponse>();
  const [queryParams, setQueryParams] = useState<QueryParams>({
    id: Number(query.get("id") || 0),
    page: Number(query.get("page") || 1),
    tab: TabList.includes(tabFromQuery) ? tabFromQuery : "doing"
  });

  const changeUrl = useCallback((params: QueryParams) => {
    setQueryParams(params);

    const searchParams = new URLSearchParams();
    if (params.id) {
      searchParams.append("id", String(params.id));
    }
    searchParams.append("tab", params.tab);
    searchParams.append("page", String(params.page));

    const newUrl = `${location.pathname}?${searchParams.toString()}`;
    history.replace(newUrl);
  }, [history, location.pathname]);

  const fetchOrders = useCallback(() => {
    api.get("/orders", {
      status: StatusMap[queryParams.tab],
      page: queryParams.page,
      limit: queryParams.tab === "doing" ? 100 : 20
    });
  }, [api, queryParams.page, queryParams.tab]);

  useEffect(() => {
    // 新しい注文があった時に自動的に画面に表示させるため
    // 対応中のタブにいる時だけ、30秒間隔でのポーリングを実行する
    if (queryParams.tab === "doing") {
      timerRef.current = setInterval(fetchOrders, 30000);
    }

    fetchOrders();

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }
    };
  }, [fetchOrders, queryParams.tab]);

  return {
    changeUrlAndFetchOrders: (params: QueryParams) => changeUrl({ page: params.page, tab: params.tab }),
    setIdToUrl: (id: number | undefined) => changeUrl({ ...queryParams, id }),
    loading,
    queryParams,
    orders: response?.orders || [],
    reloadOrders: fetchOrders,
    totalPages,
    loaded
  };
};

export default OrderManagementView;
