import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Icon,
  styled,
  Checkbox,
  FormControlLabel
} from "@mui/material";
import { useHistory } from "react-router";
import { useApi } from "../../../lib/hooks";
import { useNotification } from "../../../providers/NotificationProvider";
import DialogTitle from "../../../components/DialogTitle";

type Props = {
  rankingId: number;
  open: boolean;
  onClose: () => void;
  status: string;
};

const RankingStopModal = ({ rankingId, open, onClose, status }: Props) => {
  const [isAgreed, setAgreed] = useState(false);
  const { showSuccessNotification } = useNotification();
  const api = useApi();
  const history = useHistory();
  const canSubmit = status !== "active" || isAgreed;

  const stopRanking = async () => {
    const response = await api.api.patch(`/rankings/${rankingId}`, { status: "stopped" });
    if (response !== null) {
      showSuccessNotification('ランキングの公開を停止しました。');
      onClose();
      history.goBack();
    }
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth="sm"
    >
      <DialogTitle onClose={onClose}>
        ランキングの公開を停止
      </DialogTitle>
      <DialogContent>
        <Box>
          このランキングの公開を停止してよろしいですか？
        </Box>
        {status === "active" && (
          <>
            <StyledNotesContainer>
              <StyledNotesTitle><StyledIcon><i className="ri-alert-line" color="" /></StyledIcon>注意事項</StyledNotesTitle>
              <Typography variant="body1">・停止中は現在このランキングに参加しているユーザーがランキングを利用できない状態になります。</Typography>
            </StyledNotesContainer>
            <FormControlLabel
              label={"上記の内容を理解しました ※必須"}
              sx={{ mt: 1 }}
              control={
                <Checkbox
                  checked={isAgreed}
                  onChange={(e) => setAgreed(e.target.checked)}
                />
              }
            />
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ pt: 2 }}>
        <Button
          variant="contained"
          fullWidth style={{ background: "#BDBDBD" }}
          onClick={onClose}>
          キャンセル
        </Button>
        <Button
          variant="contained"
          fullWidth
          color="danger"
          onClick={stopRanking}
          disabled={!canSubmit}
        >
          公開を停止する
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const StyledNotesContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5),
  backgroundColor: "#FFF3F3",
  display: "flex",
  flexDirection: "column"
}));

const StyledNotesTitle = styled(Typography)(({ theme }) => ({
  color: "#555555",
  display: "flex",
  fontWeight: 600,
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1)
}));

const StyledIcon = styled(Icon)({
  color: "#E46453",
  display: "flex",
  alignItems: "center",
  fontWeight: "normal"
});

export default RankingStopModal;
