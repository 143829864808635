import React, { useEffect, useState, useCallback } from "react";
import { Row, Col, ButtonToolbar, DropdownButton, MenuItem, Modal, Button, FormGroup, Tooltip, OverlayTrigger } from "react-bootstrap";
import { useHistory } from "react-router";
import { styled } from "@mui/material/styles";
import { Pagination } from "@mui/material";
import Flatpickr from "react-flatpickr";
import { Japanese } from "flatpickr/dist/l10n/ja";
import { CalendarOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { AutoCouponContentStatusToJpObj } from "../../../containers/entities/CouponContentEntityContainer";
import { categoryToJpObj } from "../../../containers/entities/AutoCouponContentEntityContainer";
import { SortOrderType } from "../../../types/common.d";
import { CouponSortByType, CouponContentResource } from "../../../types/coupon";
import { CouponContentsResponse } from "../../../types/api/coupon.d";
import { useApi, usePcSizeFlag } from "../../../lib/hooks";
import { Card } from "../../../components/Card/Card";
import ContentSummaryCard from "../../../components/Card/ContentSummaryCard";
import DisablingMask from "../../../components/DisablingMask";
import AutoCouponTable from "./AutoCouponTable";
import StatusLabel from "./StatusLabel";
import OptionButton from "../../../components/CustomButton/OptionButton";
import { useLoginContext } from "../../../providers/LoginContextProvider";

// 日付フォーマット
const formatDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}/${month}/${day}`;
};

// 期間計算
const calculatePeriod = (period: string) => {
  const now = new Date();
  let fromDate, toDate;

  switch (period) {
    case "今日":
      fromDate = toDate = formatDate(now);
      break;
    case "昨日":
      const yesterday = new Date(now);
      yesterday.setDate(now.getDate() - 1);
      fromDate = toDate = formatDate(yesterday);
      break;
    case "今週":
      const weekStart = new Date(now);
      weekStart.setDate(now.getDate() - now.getDay());
      fromDate = formatDate(weekStart);
      toDate = formatDate(now);
      break;
    case "先週":
      const lastWeekStart = new Date(now);
      lastWeekStart.setDate(now.getDate() - now.getDay() - 7);
      const lastWeekEnd = new Date(now);
      lastWeekEnd.setDate(now.getDate() - now.getDay() - 1);
      fromDate = formatDate(lastWeekStart);
      toDate = formatDate(lastWeekEnd);
      break;
    case "今月":
      const monthStart = new Date(now.getFullYear(), now.getMonth(), 1);
      fromDate = formatDate(monthStart);
      toDate = formatDate(now);
      break;
    case "先月":
      const lastMonthStart = new Date(now.getFullYear(), now.getMonth() - 1, 1);
      const lastMonthEnd = new Date(now.getFullYear(), now.getMonth(), 0);
      fromDate = formatDate(lastMonthStart);
      toDate = formatDate(lastMonthEnd);
      break;
    case "期間を指定":
      break;
    default:
      fromDate = toDate = formatDate(now);
      break;
  }

  return { fromDate, toDate };
};

const SortLabels = {
  read_count: {
    desc: "開封数の多い順",
    asc: "開封数の少ない順"
  },
  used_count: {
    desc: "利用数の多い順",
    asc: "利用数の少ない順"
  },
} as const;

const SortFields = [
  { sortBy: "read_count", sortOrder: "desc" },
  { sortBy: "read_count", sortOrder: "asc" },
  { sortBy: "used_count", sortOrder: "desc" },
  { sortBy: "used_count", sortOrder: "asc" },
] as const;

type FetchApiParams = Partial<{
  page: number;
  sortBy: CouponSortByType;
  sortOrder: SortOrderType;
  fromDate?: string;
  toDate?: string;
}>;

type Props = {
  onDeleteCouponContent: (id: number) => void;
  onClickCopyButton: (couponContent: CouponContentResource) => void;
  loading: boolean;
};

const AutoCouponList = ({
  onDeleteCouponContent,
  onClickCopyButton,
  loading
}: Props): JSX.Element => {
  const history = useHistory();
  const isPcOrTablet = usePcSizeFlag();
  const limit = isPcOrTablet ? 20 : 4;
  const [sortBy, setSortBy] = useState<CouponSortByType>(SortFields[0].sortBy);
  const [sortOrder, setSortOrder] = useState<SortOrderType>(SortFields[0].sortOrder);
  const { api: couponContentGetApi, response, totalPages, currentPage } = useApi<CouponContentsResponse>();
  const autoCouponContents = response?.coupon_contents || [];
  const { currentStore } = useLoginContext();
  const showDeliveredCount = currentStore.show_coupon_delivered_count;
  const showArrivalCount = currentStore.show_coupon_arrival_count;
  const showReadCount = currentStore.show_coupon_read_count;

  // 期間指定の状態管理
  const [period, setPeriod] = useState<string>("今月");
  const [fromDate, setFromDate] = useState<string | undefined>();
  const [toDate, setToDate] = useState<string | undefined>();
  const [showDateRangeModal, setShowDateRangeModal] = useState(false);
  const handlePeriodChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedPeriod = event.target.value;
    if (selectedPeriod === "期間を指定") {
      setShowDateRangeModal(true);
    } else {
      setPeriod(selectedPeriod);
    }
    setPeriod(selectedPeriod);
  };
  const handleApplyDateRange = (from: string | undefined, to: string | undefined) => {
    setPeriod("期間を指定");

    if (from || to) {
      setFromDate(from);
      setToDate(to);
      getAutoCouponContents({ fromDate: from, toDate: to });
    }
  };

  const getAutoCouponContents = useCallback(async (params: FetchApiParams = {}) => {
    const { page = 1, sortBy = SortFields[0].sortBy, sortOrder = SortFields[0].sortOrder, fromDate, toDate } = params;

    const query: Record<string, any> = {
      type: "auto",
      page,
      sort: sortBy,
      order: sortOrder,
      limit,
    };

    // fromDate と toDate があればqueryに追加
    if (fromDate) query.from = fromDate;
    if (toDate) query.to = toDate;

    const res = await couponContentGetApi.get("/coupon_contents", query);
    if (!res) return;

    setSortBy(sortBy);
    setSortOrder(sortOrder);
  }, [limit, couponContentGetApi]);

  useEffect(() => {
    const { fromDate, toDate } = calculatePeriod(period);
    setFromDate(fromDate);
    setToDate(toDate);
    getAutoCouponContents({ fromDate, toDate });
  }, [period, getAutoCouponContents]);

  return (
    <Row>
      <Col md={12}>
        <Card
          headerStyle={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
          title="自動配信"
          rightContent={(
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <StyledAggregationPeriod>
                  集計期間
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip style={{ fontSize: "0.8em", maxWidth: "400px", width: "400px" }} id="unique-tooltip-id">
                        <div style={{ textAlign: "left" }}>
                          指定した期間に配信したクーポンの配信数、開封数、利用数を表示できます。
                          指定期間内の開封数、利用数ではありませんのでご注意下さい。（配信日での集計）
                        </div>
                      </Tooltip>
                    }
                  >
                    <QuestionCircleOutlined style={{ marginLeft: "5px", fontSize: "1.0em", color: "#888888", cursor: "pointer" }} />
                  </OverlayTrigger>
                </StyledAggregationPeriod>
                <select
                  className="form-control"
                  style={{ marginLeft: "10px", height: "38px", fontSize: "0.9em" }}
                  value={period}
                  onChange={handlePeriodChange}
                >
                  <option value="今日">今日</option>
                  <option value="昨日">昨日</option>
                  <option value="今週">今週</option>
                  <option value="先週">先週</option>
                  <option value="今月">今月</option>
                  <option value="先月">先月</option>
                  <option value="期間を指定">期間を指定</option>
                </select>
                {period === "期間を指定" && (fromDate || toDate) && (
                  <StyledDateRange onClick={() => setShowDateRangeModal(true)}>
                    {`${fromDate || ""} 〜 ${toDate || ""}`}
                    <CalendarOutlined style={{ marginLeft: "5px", fontSize: "1em", color: "#495057" }} />
                  </StyledDateRange>
                )}
              </div>
              {!isPcOrTablet && (
                <ButtonToolbar className="pull-right">
                  <DropdownButton
                    title={SortLabels[sortBy][sortOrder]}
                    bsSize="small"
                    style={{ border: "none", color: "#000" }}
                    pullRight
                    id="dropdown-coupon-list"
                  >
                    {SortFields.map((item) => (
                      <MenuItem key={item.sortBy + item.sortOrder} onClick={() =>
                        getAutoCouponContents({
                          sortBy: item.sortBy,
                          sortOrder: item.sortOrder,
                          fromDate,
                          toDate,
                        })
                      }>
                        {SortLabels[item.sortBy][item.sortOrder]}
                      </MenuItem>
                    ))}
                  </DropdownButton>
                </ButtonToolbar>
              )}
            </div>
          )}
          content={
            isPcOrTablet ? (
              <DisablingMask mask={loading}>
                <AutoCouponTable
                  autoCouponContents={autoCouponContents}
                  onClickCouponContent={(id) => history.push(`auto_coupon_contents/edit?type=auto&coupon_content_id=${id}`)}
                  onDeleteCouponContent={(id) => onDeleteCouponContent(id)}
                  onClickCopyButton={onClickCopyButton}
                  sortBy={sortBy}
                  sortOrder={sortOrder}
                  onSort={(newSortBy, newSortOrder) => {
                    getAutoCouponContents({
                      sortBy: newSortBy,
                      sortOrder: newSortOrder,
                      fromDate,
                      toDate
                    });
                  }}
                />
              </DisablingMask>
            ) : (
              autoCouponContents.map((item, index) => (
                <StyledContentSummaryCard
                  key={item.coupon_content.id}
                  last={autoCouponContents.length - 1 === index}
                  onClick={() => history.push(`auto_coupon_contents/edit?type=auto&coupon_content_id=${item.coupon_content.id}`)}
                  options={
                    <OptionButton
                      options={[{
                        label: "複製",
                        onClick: () => onClickCopyButton(item.coupon_content),
                      }, {
                        label: "削除",
                        labelColor: "#FF4A55",
                        onClick: () => onDeleteCouponContent(item.coupon_content.id),
                      }]}
                    />
                  }
                  imageUrl={item.coupon_content.image_url}
                  title={item.coupon_content.benefit}
                  label={
                    <StatusLabel status={item.coupon_content.status} size="small">
                      {AutoCouponContentStatusToJpObj[item.coupon_content.status]}
                    </StatusLabel>
                  }
                  note={
                    item.auto_publish_condition
                      ? categoryToJpObj[item.auto_publish_condition.category]
                      : ""
                  }
                  {...(showDeliveredCount && { deliveredCount: item.delivered_count})}
                  {...(showReadCount && { readCount: item.read_count})}
                  {...(showReadCount && { readCountDiff: item.read_count_diff})}
                  usedCount={item.used_count}
                  usedCountDiff={item.used_count_diff}
                  salesEffect={item.sales_effect}
                  {...(showArrivalCount && { arrivalCount: item.arrival_count })}
                  createdAt={item.coupon_content.created_at}
                  memo={item.coupon_content.memo}
                  hasAvailableArrivalCount={item.has_available_arrival_count}
                />
              ))
            )
          }
          stats={
            totalPages > 1 ? (
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={(_, page) => getAutoCouponContents({ page, sortBy, sortOrder, fromDate, toDate })}
                sx={{ fontSize: 24, color: "black" }}
              />
            ) : null
          }
        />
      </Col>
      <DateRangeModal
        show={showDateRangeModal}
        handleClose={() => setShowDateRangeModal(false)}
        handleApply={handleApplyDateRange}
      />
    </Row>
  );
};

const StyledContentSummaryCard = styled(ContentSummaryCard, {
  shouldForwardProp: (prop: string) => "last" !== prop,
})<{ last: boolean }>(({ theme, last }) => ({
  padding: theme.spacing(2, 0),
  borderBottom: last ? "none" : "1px solid rgba(0, 0, 0, 0.1)",
}));

const StyledAggregationPeriod = styled("span")`
  color: #888888;
  margin-right: 5px;
  font-size: 0.9em;
  font-weight: 300;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

const StyledDateRange = styled("div")`
  display: flex;
  margin-left: 4px;
  border: 1px solid #e3e3e3;
  border-radius: 0.25rem;
  height: 38px;
  padding: 0.375rem 0.75rem;
  color: #495057;
  background-color: #fff;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  font-size: 0.8em;
`;

const DateRangeModal = ({ show, handleClose, handleApply }) => {
  const [from, setFrom] = useState<string | undefined>(undefined);
  const [to, setTo] = useState<string | undefined>(undefined);

  const onApply = () => {
    let updatedFrom = from;
    let updatedTo = to;

    if (updatedFrom && updatedTo) {
      const fromDT = new Date(updatedFrom);
      const toDT = new Date(updatedTo);
      if (fromDT > toDT) {
        [updatedFrom, updatedTo] = [updatedTo, updatedFrom];
        setFrom(updatedFrom);
        setTo(updatedTo);
      }
    }
    if (updatedFrom || updatedTo) {
      handleApply(updatedFrom, updatedTo);
    }
    handleClose();
  };

  const onReset = () => {
    setFrom(undefined);
    setTo(undefined);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>期間絞り込み</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>期間</h5>
        <FormGroup controlId="formFromDate">
          <Flatpickr
            id="formFromDate"
            value={from || ""}
            onChange={(date: Date[]) => {
              if (Array.isArray(date) && date.length > 0 && date[0] instanceof Date && !isNaN(date[0].getTime())) {
                setFrom(date[0].toLocaleDateString("ja-JP", { year: "numeric", month: "2-digit", day: "2-digit" }).replace(/\//g, "-"));
              }
            }}
            placeholder="いつから"
            options={{
              altInput: true,
              allowInput: true,
              locale: Japanese,
              altFormat: "Y年m月d日",
              dateFormat: "Y/m/d",
            }}
          />
        </FormGroup>
        <FormGroup controlId="formToDate">
          <Flatpickr
            id="formToDate"
            value={to || ""}
            onChange={(date: Date[]) => {
              if (Array.isArray(date) && date.length > 0 && date[0] instanceof Date && !isNaN(date[0].getTime())) {
                setTo(date[0].toLocaleDateString("ja-JP", { year: "numeric", month: "2-digit", day: "2-digit" }).replace(/\//g, "-"));
              }
            }}
            placeholder="いつまで"
            options={{
              altInput: true,
              allowInput: true,
              locale: Japanese,
              altFormat: "Y年m月d日",
              dateFormat: "Y/m/d",
            }}
          />
        </FormGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn-primary"
          style={{
            backgroundColor: "#1EC7EA",
            color: "#fff",
            border: "none",
            width: "100%",
            marginBottom: "10px",
          }}
          onClick={onApply}
        >
          この条件で絞り込む
        </Button>
        <Button
          className="btn-secondary"
          style={{
            width: "100%",
            color: "#A0A0A0",
            border: "none",
            backgroundColor: "transparent",
          }}
          onClick={onReset}
        >
          条件をリセットする
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AutoCouponList;
