import React, { useEffect, useCallback } from "react";
import { styled } from "@mui/material/styles";
import { Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Pagination } from "@mui/material";
import { NoticeContentsResponse } from "../../../types/api/notice.d";
import {
  AutoNoticeContentStatusToJpObj,
  CategoryToJpObj,
  NoticeStatusColors,
  NoticeStatusType,
} from "../../../containers/entities/NoticeEntityContainer";
import { useNotification } from "../../../providers/NotificationProvider";
import { useApi, usePcSizeFlag } from "../../../lib/hooks";
import { Card } from "../../../components/Card/Card";
import ContentSummaryCard from "../../../components/Card/ContentSummaryCard";
import DisablingMask from "../../../components/DisablingMask";
import AutoNoticeTable from "./AutoNoticeTable";
import { useLoginContext } from "../../../providers/LoginContextProvider";

type NoticeContentsProps = {
  page?: number;
};

const AutoNoticeList = (): JSX.Element => {
  const history = useHistory();
  const { showSuccessNotification } = useNotification();
  const isPcOrTablet = usePcSizeFlag();
  const PAGE_LIMIT = isPcOrTablet ? 20 : 4;
  const { currentStore } = useLoginContext();
  const showDeliveredCount = currentStore.show_notice_delivered_count;
  const showArrivalCount = currentStore.show_notice_arrival_count;
  const showReadCount = currentStore.show_notice_read_count;

  const { api, loading } = useApi();
  const {
    api: autoNoticeContentsGetApi,
    response: autoNoticeContentsResponse,
    headers: autoNoticeContentsResHeaders,
  } = useApi();

  const getAutoNoticeContents = useCallback(
    async (params: NoticeContentsProps = {}) => {
      const page = params.page || 1;
      const res = await autoNoticeContentsGetApi.get({
        uri: "/notice_contents",
        params: {
          type: "auto",
          page: page,
          limit: PAGE_LIMIT,
        },
      });

      if (!res) return;
    },
    [autoNoticeContentsGetApi, PAGE_LIMIT]
  );

  const autoNoticeContents: NoticeContentsResponse["notice_contents"] =
    autoNoticeContentsResponse?.notice_contents || [];

  useEffect(() => {
    getAutoNoticeContents();
  }, [getAutoNoticeContents]);

  const deleteNoticeContent = async (id: number): Promise<void> => {
    if (!window.confirm("このお知らせを削除しますか？")) return;

    const res = await api.delete(`/notice_contents/${id}`);
    if (!res) return;

    getAutoNoticeContents();
    showSuccessNotification("お知らせを削除しました。");
  };

  return (
    <Row>
      <Col md={12}>
        <Card
          title="自動配信"
          content={
            isPcOrTablet ? (
              <DisablingMask mask={loading}>
                <AutoNoticeTable
                  autoNoticeContents={autoNoticeContents}
                  goToEdit={(id) => history.push(`notices/edit?notice_content_id=${id}`)}
                  deleteNoticeContent={(id) => deleteNoticeContent(id)}
                />
              </DisablingMask>
            ) : (
              autoNoticeContents.map(
                (
                  {
                    notice_content,
                    delivered_count,
                    read_count,
                    read_count_diff,
                    auto_publish_condition,
                    arrival_count
                  },
                  index
                ) => (
                  <ContentSummaryCard
                    key={notice_content.id}
                    onClick={() => history.push(`notices/edit?notice_content_id=${notice_content.id}`)}
                    imageUrl={notice_content.image_urls[0] || ""}
                    title={notice_content.title}
                    label={
                      <StyledStatusLabel status={notice_content.status}>
                        {AutoNoticeContentStatusToJpObj[notice_content.status]}
                      </StyledStatusLabel>
                    }
                    note={auto_publish_condition ? CategoryToJpObj[auto_publish_condition.category] : ""}
                    style={{
                      padding: "16px 0",
                      borderBottom:
                        autoNoticeContents.length - 1 === index
                          ? "none"
                          : "1px solid rgba(0, 0, 0, 0.1)",
                    }}
                    {...(showDeliveredCount && { deliveredCount: delivered_count })}
                    {...(showReadCount && { readCount: read_count })}
                    {...(showReadCount && { readCountDiff: read_count_diff })}
                    createdAt={notice_content.created_at}
                    memo={notice_content.memo}
                    {...(showArrivalCount && { arrivalCount: arrival_count })}
                  />
                )
              )
            )
          }
          stats={
            autoNoticeContentsResHeaders &&
            Number(autoNoticeContentsResHeaders["total-pages"]) > 1 ? (
              <Pagination
                count={Number(autoNoticeContentsResHeaders["total-pages"])}
                page={Number(autoNoticeContentsResHeaders["current-page"])}
                onChange={(_, value) =>
                  getAutoNoticeContents({
                    page: value,
                  })
                }
                sx={{ fontSize: 24, color: "black" }}
              />
            ) : null
          }
        />
      </Col>
    </Row>
  );
};

const StyledStatusLabel = styled("div", {
  shouldForwardProp: (prop) => prop !== "status",
})<{ status: NoticeStatusType }>(({ status, theme }) => ({
  backgroundColor: NoticeStatusColors[status],
  display: "inline",
  color: "white",
  padding: theme.spacing(0.5, 1),
  border: 0,
  borderRadius: 6,
  fontWeight: 700,
  fontSize: 10,
}));

export default AutoNoticeList;
