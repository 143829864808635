import React, { useState, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { CircularProgress } from "@mui/material";
import { useQuery, useGetApiCall } from "../../lib/hooks";
import { RankingResource } from "../../types/ranking";
import { rankingProgressStatus } from "../../constants/ranking";
import { DisplayErrorNotification, DisplaySuccessNotification } from "../../types/common.d";
import RankingStatusChip from "../../components/Chip/RankingStatusChip";
import RankingUserListView from "./parts/RankingUserListView";
import RankingContentForm from "./parts/RankingContentForm";
import RankingRewardListTable from "./parts/RankingRewardListTable";

const TabList = ["users", "content", "rewards"];
type TabType =  typeof TabList[number];
type QueryParams = {
  id: number;
  tab: TabType;
};

type Props = {
  displayErrorNotification: DisplayErrorNotification;
  displaySuccessNotification: DisplaySuccessNotification;
};

const RankingDetailView = ({
  displayErrorNotification,
  displaySuccessNotification
}: Props): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const rankingId = Number(query.get("id"));
  const tabFromQuery = query.get("tab") as TabType;

  const [queryParams, setQueryParams] = useState<QueryParams>({
    id: rankingId,
    tab: TabList.includes(tabFromQuery) ? tabFromQuery : "users"
  });

  const changeUrl = useCallback((params: QueryParams) => {
    setQueryParams(params);

    const searchParams = new URLSearchParams();
    searchParams.append("id", String(params.id));
    searchParams.append("tab", params.tab);

    const newUrl = `${location.pathname}?${searchParams.toString()}`;
    history.replace(newUrl);
  }, [history, location.pathname]);

  const url = `/rankings/${rankingId}`;
  const { response: ranking, loading } = useGetApiCall<RankingResource>(url);

  const periodic = ranking?.ranking_content.is_periodic_ranking;
  const start_datetime = moment(ranking?.start_datetime).format('YYYY/MM/DD HH:mm')
  const end_datetime = moment(ranking?.end_datetime).format('YYYY/MM/DD HH:mm')

  if (!ranking) {
    return (<CircularProgress />);
  }
  const status = rankingProgressStatus(ranking.status, ranking.start_datetime, ranking.end_datetime);

  return (
    <>
      <Box p={2} sx={{ display: "flex", gap: 2,  bgcolor: "#fff"}}>
        <RankingStatusChip status={status} />
        <Box>
          <Box sx={{ fontWeight: "bold" }}>{ranking.name}</Box>
          <Box>
            {periodic
                ? `定期・${ranking.ranking_content.period_in_months}ヶ月ごと`
                : "期間限定"
            }
            {periodic
                ? `/ 現在の集計期間：[${ranking.ranking_content.finished_count + 1}回目]${start_datetime} 〜 ${end_datetime}`
                : `/ ${end_datetime}終了予定`
            }
          </Box>
        </Box>
      </Box>
      <Box bgcolor="#fff">
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={queryParams.tab}
          onChange={(_, tab: string) => changeUrl({ id: rankingId, tab })}
        >
          <Tab value="users" label="参加ユーザー" disabled={loading} />
          <Tab value="content" label="ランキング設定" disabled={loading} />
          {false && (
            <Tab value="reward" label="特典" disabled={loading} />
          )}
        </Tabs>
      </Box>

      {queryParams.tab === "users" && (
        <RankingUserListView ranking={ranking} />
      )}
      {queryParams.tab === "content" && (
        <RankingContentForm
          target="ranking"
          defaultRanking={ranking}
          periodic={!!periodic}
          displayErrorNotification={displayErrorNotification}
          displaySuccessNotification={displaySuccessNotification}
          rankingStarted={status !== "waiting"}
        />
      )}
      {queryParams.tab === "reward" && (
        <RankingRewardListTable ranking={ranking} />
      )}
    </>
  );
};

export default RankingDetailView;
