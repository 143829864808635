import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import moment from "moment";
import { RankingContentResource } from "../../../types/rankingContent";
import { RankingCategoryLabels } from "../../../constants/ranking";
import RankingContentStatusChip from "../../../components/Chip/RankingContentStatusChip";

type Props = {
  contents: RankingContentResource[];
  onClickRankingContent: (id: number) => void;
};

const LaptopRankingContentListTable = ({ contents, onClickRankingContent }: Props): JSX.Element => {
  return (
    <Table size="small">
      <TableHead>
        <StyledHeaderRow>
          <TableCell width={100}>状態</TableCell>
          <TableCell>ランキング名</TableCell>
          <TableCell width={100}>周期</TableCell>
          <TableCell width={200}>定期ランキング開始日</TableCell>
          <TableCell width={100}>ランキング計測対象</TableCell>
          <TableCell width={100}>特典</TableCell>
          <TableCell width={100}>実施済</TableCell>
        </StyledHeaderRow>
      </TableHead>

      <TableBody>
        {contents.map((content) => (
          <StyledBodyRow key={content.id} hover onClick={() => onClickRankingContent(content.id)}>
            <TableCell><RankingContentStatusChip status={content.status} /></TableCell>
            <TableCell>{content.name}</TableCell>
            <TableCell>
              {content.period_in_months}ヶ月
              {content.period_in_weeks !== 0 && (<>{content.period_in_weeks}週間</>)}
              {content.period_in_days !== 0 && (<>{content.period_in_days}日</>)}
              ごと
            </TableCell>
            <TableCell>
              {moment(content.start_datetime).format("YYYY/M/D HH:mm")}
            </TableCell>
            <TableCell>{RankingCategoryLabels[content.category]}</TableCell>
            <TableCell>{content.rewards.length}件</TableCell>
            <TableCell>{content.finished_count}回</TableCell>
          </StyledBodyRow>
        ))}
      </TableBody>
    </Table>
  );
};

const StyledHeaderRow = styled(TableRow)({
  "& > th": {
    whiteSpace: "nowrap"
  }
});

const StyledBodyRow = styled(TableRow)(({ theme }) => ({
  cursor: "pointer",
  "& > td": {
    fontSize: 13,
    fontWeight: 700,
    padding: theme.spacing(3.5, 1)
  }
}));

export default LaptopRankingContentListTable;
