import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Row, Col, ButtonToolbar, DropdownButton, MenuItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useApi, usePcSizeFlag } from "../../lib/hooks";
import GuideButton from "../../components/Link/GuideLink";
import CustomButton from "../../components/CustomButton/CustomButton";
import NoticeCreationConduit from "./parts/NoticeCreationConduit";
import NoticeList from "./parts/NoticeList";
import AutoNoticeList from "./parts/AutoNoticeList";
import { AllNoticesAggregationResponse } from "../../types/api/notice";
import PeriodSpecifiableContentSummaryCard from "../../components/Card/PeriodSpecifiableContentSummaryCard";
import { useLoginContext } from "../../providers/LoginContextProvider";

const NoticeListView = (): JSX.Element => {
  const isPcOrTablet = usePcSizeFlag();
  const history = useHistory();
  const { api: allNoticesAggregationApi, response } = useApi<AllNoticesAggregationResponse>();
  const { currentStore } = useLoginContext();
  const showDeliveredCount = currentStore.show_notice_delivered_count;
  const showArrivalCount = currentStore.show_notice_arrival_count;
  const showReadCount = currentStore.show_notice_read_count;

  const getAllNoticesAggregation = useCallback(async (params = {}) => {
    const { fromDate, toDate } = params;
    const query: Record<string, any> = {};

    if (fromDate) query.from = fromDate;
    if (toDate) query.to = toDate;

    await allNoticesAggregationApi.get("/all_notices_aggregation", query);
  }, [allNoticesAggregationApi]);

  return (
    <div className="content">
      <Grid fluid>
        <Row style={{ marginBottom: 10 }}>
          <Col
            md={12}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 10,
              alignItems: isPcOrTablet ? "center" : "flex-end",
              flexDirection: isPcOrTablet ? "row" : "column",
            }}
          >
            <GuideButton
              label="お知らせ"
              link="https://toypo.notion.site/6506d0b1ef9a41f78482aa28f642a8cc"
            />

            <LinkContainer to="/admin/notices/templates">
              <CustomButton bsStyle="default" pullRight>
                <b>配信テンプレート</b>
              </CustomButton>
            </LinkContainer>

            <ButtonToolbar className="pull-right">
              <DropdownButton
                title={<b>お知らせを作成する</b>}
                className="btn-info btn-fill"
                pullRight
                id="dropdown-template-list"
              >
                <MenuItem eventKey="1" onClick={() => history.push("notices/new?type=normal")}>
                  通常配信お知らせを作成
                </MenuItem>
                <MenuItem eventKey="2" onClick={() => history.push("notices/new?type=auto")}>
                  自動配信お知らせを作成
                </MenuItem>
              </DropdownButton>
            </ButtonToolbar>
          </Col>
        </Row>

        <NoticeCreationConduit
          onCreateNotice={() => history.push("notices/new?type=normal")}
          onCreateAutoNotice={() => history.push("notices/new?type=auto")}
        />

        {(showDeliveredCount || showArrivalCount || showReadCount) && (
          <PeriodSpecifiableContentSummaryCard
            title={"お知らせ集計"}
            onChangePeriod={getAllNoticesAggregation}
            {...(showDeliveredCount && { deliveredCount: response?.delivered_count || 0 })}
            {...(showArrivalCount && { arrivalCount: response?.arrival_count || 0 })}
            {...(showReadCount && { readCount: response?.read_count || 0 })}
            hasAvailableArrivalCount={response?.has_available_arrival_count}
          />
        )}

        <NoticeList />

        <AutoNoticeList />
      </Grid>
    </div>
  );
};

export default NoticeListView;
