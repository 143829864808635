import React, { useEffect, useCallback, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import CircularProgress from "@mui/material/CircularProgress";
import { downloadCSVFile } from "../../lib/general";
import { useApi, useQuery, useBooleanState, useMobileFlag, usePcSizeFlag } from "../../lib/hooks";
import { StoreBenchmarkTypes, StoreBenchmarkIndicators } from "../../constants/storeBenchmark";
import { StoreBenchmarksResponse, StoreBenchmarkType, StoreBenchmarkIndicator } from "../../types/storeBenchmark";
import ConditionModal from "./parts/ConditionModal";
import { useLoginContext } from "../../providers/LoginContextProvider";
import DisablingMask from "../../components/DisablingMask";
import LaptopBenchmarkList from "./parts/LaptopBenchmarkList";
import MobileBenchmarkList from "./parts/MobileBenchmarkList";
import BenchmarkFilterConditionLabel, { isNoCondition } from "./parts/BenchmarkFilterConditionLabel";
import { QueryParams } from "./type";

const BenchmarkListView = (): JSX.Element => {
  const isMobile = useMobileFlag();
  const isPcOrTablet = usePcSizeFlag();
  const history = useHistory();
  const { currentStore } = useLoginContext();
  const csvApi = useApi();
  const [isConditionModalOpen, openConditionModal, closeConditionModal] = useBooleanState(false);
  const [csvAnchorEl, setCsvAnchorEl] = useState<HTMLButtonElement | null>(null);
  const {
    benchmarks,
    queryParams,
    changeUrlAndFetchStoreBenchmarks,
    loaded,
    loading,
    totalPages
  } = useStoreBenchmarkApi();

  const downloadCsv = async (csvType: "monthly" | "daily") => {
    const params = {
      type: queryParams.type,
      date_to: queryParams.dateTo,
      date_from: queryParams.dateFrom,
      indicator: queryParams.indicator,
      store_ids: queryParams.storeIds,
      csv_type: csvType
    };
    const response = await csvApi.api.blob("/store_benchmarks.csv", params);
    downloadCSVFile(response);
    setCsvAnchorEl(null);
  };

  const ListComponent = isMobile ? MobileBenchmarkList : LaptopBenchmarkList;

  return (
    <Paper
      variant={isMobile ? "elevation" : "outlined"}
      square={isMobile}
      sx={{ m: isMobile ? 0 : 2 }}
    >
      <StyledConditionContainer breaks={isMobile && !isNoCondition(queryParams)}>
        <BenchmarkFilterConditionLabel queryParams={queryParams} />

        <Box display="flex" gap={1} >
          <Button
            disabled={loading}
            variant="outlined"
            color="cancel"
            onClick={openConditionModal}
          >
            絞り込み設定
          </Button>

          <ConditionModal
            open={isConditionModalOpen}
            monthly={queryParams.type === "monthly"}
            defaultCondition={{
              indicator: queryParams.indicator,
              dateFrom: queryParams.dateFrom,
              dateTo: queryParams.dateTo,
              storeIds: queryParams.storeIds
            }}
            onClose={closeConditionModal}
            onSubmit={(condition) => {
              changeUrlAndFetchStoreBenchmarks({ ...queryParams, ...condition });
            }}
          />

          {isPcOrTablet && (
            <Button
              disabled={csvApi.loading || loading}
              variant="outlined"
              color="submit"
              endIcon={<i className="ri-arrow-down-s-fill" style={{ lineHeight: "1" }} />}
              onClick={(e) => setCsvAnchorEl(e.currentTarget)}
            >
              CSV出力
            </Button>
          )}

          <Menu
            anchorEl={csvAnchorEl}
            open={Boolean(csvAnchorEl)}
            onClose={() => setCsvAnchorEl(null)}
          >
            <MenuItem disabled={loading} onClick={() => downloadCsv("monthly")}>月次で出力</MenuItem>
            <MenuItem disabled={loading} onClick={() => downloadCsv("daily")}>日次で出力</MenuItem>
          </Menu>

          {currentStore.store_type === "child" && (
            <Button
              disabled={loading}
              variant="contained"
              color="submit"
              onClick={() => history.push("/admin/benchmark/new")}
            >
              目標を作成
            </Button>
          )}
        </Box>
      </StyledConditionContainer>

      <Tabs
        value={queryParams.type}
        onChange={(_, type: string) => {
          changeUrlAndFetchStoreBenchmarks({ ...queryParams, type, page: 1 })
        }}
      >
        <Tab disabled={loading} value="monthly" label="月次目標" />
        <Tab disabled={loading} value="temporary" label="キャンペーン・施策目標" />
      </Tabs>

      <Box mt={isMobile ? 0 : 2} p={isMobile ? 0 : 1}>
        {loaded ? (
          <DisablingMask mask={loading}>
            <ListComponent
              benchmarks={benchmarks}
              onClick={(benchmark) => {
                benchmark.benchmark_progress === "before"
                  ? history.push(`/admin/benchmark/${benchmark.id}?tab=benchmark`)
                  : history.push(`/admin/benchmark/${benchmark.id}`);
              }}
            />
          </DisablingMask>
        ) : (
          <Box display="flex" justifyContent="center" my={5}>
            <CircularProgress />
          </Box>
        )}

        {totalPages > 1 && (
          <Pagination
            sx={{ mt: 1 }}
            count={totalPages}
            page={queryParams.page}
            onChange={(_, page) => changeUrlAndFetchStoreBenchmarks({ ...queryParams, page })}
          />
        )}
      </Box>
    </Paper>
  );
};

const useStoreBenchmarkApi = () => {
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const type = query.get("type") as StoreBenchmarkType;
  const indicator = query.get("indicator") as StoreBenchmarkIndicator;
  const { api, response, loading, loaded, totalPages } = useApi<StoreBenchmarksResponse>();
  const [queryParams, setQueryParams] = useState<QueryParams>({
    page: Number(query.get("page") || 1),
    type: StoreBenchmarkTypes.includes(type) ? type : "monthly",
    dateTo: query.get("date_to") || null,
    dateFrom: query.get("date_from") || null,
    indicator: StoreBenchmarkIndicators.includes(indicator) ? indicator : null,
    storeIds: query.getAll("store_ids[]").map(Number) || []
  });

  const changeUrl = useCallback((params: QueryParams) => {
    setQueryParams(params);

    const searchParams = new URLSearchParams();
    searchParams.append("page", String(params.page));
    searchParams.append("type", params.type);
    if (params.dateTo) searchParams.append("date_to", params.dateTo);
    if (params.dateFrom) searchParams.append("date_from", params.dateFrom);
    if (params.indicator) searchParams.append("indicator", params.indicator);

    const storeIds = params.storeIds.length > 0
      ? "&" + params.storeIds.map((id) => `store_ids[]=${id}`).join("&")
      : "";

    const newUrl = `${location.pathname}?${searchParams.toString()}${storeIds}`;
    history.replace(newUrl);
  }, [history, location.pathname]);

  useEffect(() => {
    const params = {
      page: queryParams.page,
      type: queryParams.type,
      date_to: queryParams.dateTo,
      date_from: queryParams.dateFrom,
      indicator: queryParams.indicator,
      store_ids: queryParams.storeIds,
      limit: 20
    };

    api.get("/store_benchmarks", params);
  }, [api, queryParams]);

  return {
    changeUrlAndFetchStoreBenchmarks: changeUrl,
    benchmarks: response?.store_benchmarks || [],
    loading,
    loaded,
    totalPages,
    queryParams
  };
};

const StyledConditionContainer = styled("div", {
  shouldForwardProp: (props) => props !== "breaks"
})<{ breaks: boolean; }>(({ breaks, theme }) => ({
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: breaks ? "column" : "row",
  justifyContent: "space-between",
  alignItems: breaks ? "flex-end" : "center",
  gap: theme.spacing(1)
}));

export default BenchmarkListView;
