import React, { ComponentPropsWithoutRef } from "react";
import { styled } from "@mui/material/styles";
import { RankingStatus } from "../../types/ranking";
import { RankingStatusLabels } from "../../constants/ranking";

const ColorsByStatus = {
  finished: {background: "#F5F5F5", text: "#757575"},
  stopped:  {background: "#F3E5F5", text: "#BA68C8"},
  waiting:  {background: "#F3E5F5", text: "#BA68C8"},
  inProgress:  {background: "#FFF3E0", text: "#FB8C00"}
} as const;

type Props = ComponentPropsWithoutRef<"div"> & {
  status: RankingStatus;
};

const RankingStatusChip = ({ status }: Props): JSX.Element => {
  return (
    <StyledChip status={status}>
      {RankingStatusLabels[status]}
    </StyledChip>
  );
};

const StyledChip = styled("div")<{
  status: string
}>(({ status, theme }) => ({
  backgroundColor: ColorsByStatus[status].background,
  display: "flex",
  color: ColorsByStatus[status].text,
  padding: theme.spacing(0.5, 1),
  border: 0,
  fontWeight: "bold",
  fontSize: 14,
  whiteSpace: "nowrap",
  justifyContent: "center",
  alignItems: "center"
}));

export default RankingStatusChip;
