import React, { useState, useEffect } from "react";
import { Modal, Form, Row, Col, HelpBlock, Alert } from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import { Japanese } from "flatpickr/dist/l10n/ja";
import { Controller, useForm } from "react-hook-form";
import { useNotification } from "../../../providers/NotificationProvider";
import FormLabel from "../../../components/FormInputs/FormLabel";
import Checkbox from "../../../components/CustomCheckbox/CustomCheckbox";
import LoadingButton from "../../../components/CustomButton/LoadingButton";
import FormInputs from "../../../components/FormInputs/FormInputs";
import SwitchForm from "../../../components/FormInputs/SwitchForm";
import SelectorForm from "../../../components/FormInputs/SelectorForm";
import TimeSelectBox from "../../../components/FormInputs/TimeSelectBox";

import OpenTimeEntityContainer, {
  SpecialOpenTimeItem,
  UpdateSpecialOpenTimeItem,
} from "../../../containers/entities/OpenTimeEntityContainer";
import { usePcSizeFlag } from "../../../lib/hooks";
import { compareValidateEventDate } from "../../../lib/validation";

type Props = {
  show: boolean;
  onHide: () => void;
  updateOpenTime: SpecialOpenTimeItem | {};
  onCreateOpenTime: () => void;
};

type RepeatType = "none" | "month";

const repeatTypeToJp: {
  none: "繰り返さない";
  month: "毎月";
} = {
  none: "繰り返さない",
  month: "毎月",
};

const OpenTimeModal: React.FC<Props> = ({
  show,
  onHide,
  updateOpenTime,
  onCreateOpenTime,
}) => {
  const isPcOrTablet = usePcSizeFlag();
  const { showSuccessNotification, showErrorNotification } = useNotification();
  const date = new Date();

  const { register, unregister, getValues, setValue, watch, handleSubmit, control, errors, reset } =
    useForm({
      defaultValues: {
        openTime: {
          id: null,
          body: "",
          title: "",
          start_year: date.getFullYear(),
          start_month: date.getMonth() + 1,
          start_date: date.getDate(),
          start_hour: null,
          start_minute: null,
          end_year: date.getFullYear(),
          end_month: date.getMonth() + 1,
          end_date: date.getDate(),
          end_hour: null,
          end_minute: null,
          is_all_day: false,
          is_holiday: false,
        },
      },
    });

  const openTimeContainer = OpenTimeEntityContainer.useContainer();
  const { createSpecialOpenTime, updateSpecialOpenTime } = openTimeContainer.logic;

  const [editMode, setEditMode] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [repeat, setRepeat] = useState<RepeatType>("none");

  useEffect(() => {
    const isEditMode = Object.keys(updateOpenTime).length !== 0;
    setEditMode(isEditMode);
  }, [updateOpenTime]);

  const validateEndTime = (value) => {
    if (!getValues("openTime.is_all_day") && !value) {
      return "終了時間を設定してください";
    }

    const startHour = getValues("openTime.start_hour");
    const startMinute = getValues("openTime.start_minute");
    const endHour = getValues("openTime.end_hour");
    const endMinute = getValues("openTime.end_minute");
    const compareValidate = compareValidateEventDate({
      startHour,
      startMinute,
      endHour,
      endMinute,
    });
    if (!getValues(`openTime.is_all_day`) && !compareValidate.validation) {
      return compareValidate.message;
    }
    return true;
  };

  useEffect(() => {
    // memo:registerのタイミングがsetValueより後になってしまう為ここに記述...
    register("openTime.id");
    register("openTime.start_year", { valueAsNumber: true });
    register("openTime.start_month", { valueAsNumber: true });
    register("openTime.start_date", { valueAsNumber: true });
    register("openTime.end_year", { valueAsNumber: true });
    register("openTime.end_month", { valueAsNumber: true });
    register("openTime.end_date", { valueAsNumber: true });

    return () => {
      unregister(["openTime"]);
    };
  }, [register, unregister]);

  useEffect(() => {
    // 編集時、条件分岐
    if (editMode) {
      const updateData = updateOpenTime as SpecialOpenTimeItem;
      // 毎月繰り返しの場合
      if (!updateData.start_year && !updateData.start_month) {
        setRepeat("month");
      } else {
        setRepeat("none");
      }

      setValue("openTime", updateData);
      setValue("openTime.start_year", updateData.start_year);
      setValue("openTime.start_month", updateData.start_month);
      setValue("openTime.end_year", updateData.end_year);
      setValue("openTime.end_month", updateData.start_month);
    } else {
      setRepeat("none");
    }
  }, [updateOpenTime, editMode, register, setValue]);

  const watchIsHoliday = watch("openTime.is_holiday");
  const watchIsAllDay = watch("openTime.is_all_day");
  const watchStartYear: number = watch("openTime.start_year");
  const watchStartMonth: number = watch("openTime.start_month");
  const watchStartDate: number = watch("openTime.start_date");

  const repeatOpenTime = (repeatType: RepeatType) => {
    setRepeat(repeatType);

    if (repeatType === "none") {
      setValue("openTime.start_year", date.getFullYear());
      setValue("openTime.start_month", date.getMonth() + 1);
      setValue("openTime.end_year", date.getFullYear());
      setValue("openTime.end_month", date.getMonth() + 1);
    }
  };

  const processFormData = (openTime: UpdateSpecialOpenTimeItem) => {
    let processData = openTime;

    // 休日 または 24時間
    if (processData.is_holiday || processData.is_all_day) {
      processData = {
        ...processData,
        is_all_day: true,
        start_hour: null,
        start_minute: null,
        end_hour: null,
        end_minute: null,
      };
    }

    // 繰り返し
    if (repeat === "month") {
      processData = {
        ...processData,
        start_year: null,
        start_month: null,
        end_year: null,
        end_month: null,
      };
    }

    return processData;
  };

  const onSubmit = async (data: { openTime: UpdateSpecialOpenTimeItem }) => {
    setSaveLoading(true);
    const processedData = processFormData(data.openTime);
    const formData = processedData;
    if (formData.id) {
      await updateSpecialOpenTime(formData)
        .then(() => {
          showSuccessNotification("更新しました。");
        })
        .catch((error) => {
          showErrorNotification(error.message);
        });
    } else {
      await createSpecialOpenTime(formData)
        .then(() => {
          showSuccessNotification("作成しました。");
        })
        .catch((error) => {
          showErrorNotification(error.message);
        });
    }

    onHide();
    reset();
    onCreateOpenTime();
    setSaveLoading(false);
  };

  const onError = () => {
    showErrorNotification("値が正しく入力されていません");
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        onHide();
        reset();
      }}
    >
      <Form onSubmit={handleSubmit(onSubmit, onError)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <b>特別営業時間を{editMode ? "編集" : "追加"}</b>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div style={{ backgroundColor: "#F4F4F5", padding: "16px" }}>
            <Alert
              style={{
                background: "#fff",
                color: "#ffbc67",
                borderRadius: "3px",
                border: "1px solid #ffbc67",
              }}
            >
              <b>「営業日」を設定</b>する場合、<b>営業時間名が未入力</b>
              であると<b>カレンダー上で表示されません</b>のでご注意ください。
            </Alert>

            <FormLabel label="営業時間名" />
            <Row
              style={{
                display: "flex",
                flexDirection: isPcOrTablet ? "row" : "column",
                alignItems: isPcOrTablet ? "center" : "normal",
                marginBottom: 15
              }}
            >
              <Col md={3} xs={4}>
                <Controller
                  name="openTime.is_holiday"
                  control={control}
                  render={({ value }) => (
                    <SwitchForm
                      formMarginBottom={0}
                      checkedText="営業日"
                      uncheckedText="休業日"
                      checked={value === false}
                      onChange={(isChecked: boolean): void => {
                        setValue("openTime.is_all_day", !isChecked);
                        setValue("openTime.is_holiday", !isChecked);
                      }}
                    />
                  )}
                />
              </Col>

              <Col md={5} xs={12}>
                <FormInputs
                  properties={[
                    {
                      formGroupStyle: {
                        marginTop: isPcOrTablet ? 0 : "16px",
                        marginBottom: 0,
                        padding: "0 16px",
                      },
                      placeholder: "営業時間名",
                      type: "text",
                      name: "openTime.title",
                      inputRef: register,
                    },
                  ]}
                />
              </Col>
            </Row>

            <FormLabel label="日程" />
            <Row style={{ display: "flex", alignItems: "center" }}>
              <Col md={4} xs={8}>
                <Flatpickr
                  options={{
                    altInput: true,
                    allowInput: true,
                    locale: Japanese,
                    altFormat: "Y年m月d日",
                    disableMobile: true
                  }}
                  value={new Date(watchStartYear, watchStartMonth - 1, watchStartDate)}
                  onChange={(newDate) => {
                    setValue("openTime.start_year", newDate[0].getFullYear());
                    setValue("openTime.start_month", newDate[0].getMonth() + 1);
                    setValue("openTime.start_date", newDate[0].getDate());
                    setValue("openTime.end_year", newDate[0].getFullYear());
                    setValue("openTime.end_month", newDate[0].getMonth() + 1);
                    setValue("openTime.end_date", newDate[0].getDate());
                  }}
                />
              </Col>
            </Row>

            <Row style={{ marginTop: 10, marginBottom: 15 }}>
              <Col md={5} xs={12}>
                <SelectorForm
                  style={{ marginBottom: 0 }}
                  onSelect={({ target }) => {
                    repeatOpenTime(target.value);
                  }}
                  value={repeat}
                  width={180}
                  options={["none", "month"].map((type) => ({
                    label: repeatTypeToJp[type],
                    value: type,
                  }))}
                />
              </Col>
            </Row>

            {!watchIsHoliday && (
              <>
                <FormLabel label="営業時間" />
                <Row
                  style={{
                    display: "flex",
                    flexDirection: isPcOrTablet ? "row" : "column",
                    alignItems: isPcOrTablet ? "baseline" : "normal",
                    minHeight: "45px",
                  }}
                >
                  <Col md={3} sm={3} xs={8}>
                    <Controller
                      name="openTime.is_all_day"
                      control={control}
                      render={({ value }) => (
                        <Checkbox
                          number="終日"
                          label="終日"
                          bsClass="success"
                          checked={value}
                          onChange={(): void => {
                            setValue("openTime.is_all_day", !value);
                          }}
                        />
                      )}
                    />
                  </Col>

                  {!watchIsAllDay && (
                    <Col md={8} sm={8} xs={12}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "baseline",
                          justifyContent: "start",
                          gap: "3px",
                          marginTop: isPcOrTablet ? 0 : "16px",
                        }}
                      >
                        {/* 時間 */}
                        <Controller
                          name="openTime.start_hour"
                          control={control}
                          rules={{
                            valueAsNumber: true,
                          }}
                          render={({ value }) => (
                            <TimeSelectBox
                              type="hour"
                              maxHour={24}
                              value={String(value).padStart(2, "0")}
                              onChange={(time: string) => setValue("openTime.start_hour", time)}
                            />
                          )}
                        />
                        {/* 分 */}
                        <Controller
                          name="openTime.start_minute"
                          control={control}
                          rules={{
                            valueAsNumber: true,
                          }}
                          render={({ value }) => (
                            <TimeSelectBox
                              type="minute"
                              value={String(value).padStart(2, "0")}
                              onChange={(time: string) => setValue("openTime.start_minute", time)}
                              minuteIncrement={5}
                            />
                          )}
                        />
                        <div style={{ margin: "0 5px" }}>〜</div>
                        {/* 時間 */}
                        <Controller
                          name="openTime.end_hour"
                          control={control}
                          rules={{
                            valueAsNumber: true,
                            validate: (value) => validateEndTime(value),
                          }}
                          render={({ value }) => (
                            <TimeSelectBox
                              type="hour"
                              maxHour={28}
                              value={String(value).padStart(2, "0")}
                              onChange={(time: string) => setValue("openTime.end_hour", time)}
                            />
                          )}
                        />
                        {/* 分 */}
                        <Controller
                          name="openTime.end_minute"
                          control={control}
                          rules={{
                            valueAsNumber: true,
                          }}
                          render={({ value }) => (
                            <TimeSelectBox
                              type="minute"
                              value={String(value).padStart(2, "0")}
                              onChange={(time: string) => setValue("openTime.end_minute", time)}
                              minuteIncrement={5}
                            />
                          )}
                        />
                      </div>
                      {!!(
                        errors?.openTime?.start_hour?.message || errors?.openTime?.end_hour?.message
                      ) && (
                        <HelpBlock className="text-danger">
                          {errors?.openTime?.start_hour?.message ||
                            errors?.openTime?.end_hour?.message}
                        </HelpBlock>
                      )}
                    </Col>
                  )}
                </Row>
              </>
            )}
            <Row>
              <Col md={9} xs={12}>
                <FormInputs
                  properties={[
                    {
                      label: "説明文",
                      attachment: "任意",
                      formGroupStyle: {
                        marginTop: "10px",
                        padding: "0 16px",
                      },
                      placeholder: "例：昼",
                      componentClass: "textarea",
                      rows: "2",
                      bsClass: "form-control",
                      name: "openTime.body",
                      inputRef: register,
                    },
                  ]}
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Row style={{ display: "flex", justifyContent: "flex-end" }}>
            <Col md={4} xs={6}>
              <LoadingButton
                type="submit"
                label={editMode ? "変更する" : "追加する"}
                loadingLabel="保存中..."
                color="info"
                fill
                block
                loading={saveLoading}
              />
            </Col>
          </Row>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default OpenTimeModal;
