import React, { ComponentPropsWithoutRef } from "react";
import { styled } from "@mui/material/styles";
import { RankingContentStatus } from "../../types/rankingContent";
import { RankingContentStatusLabels } from "../../constants/ranking";

const ColorsByStatus = {
  active:  {background: "#E8F5E9", text: "#66BB6A"},
  inactive:  {background: "#F5F5F5", text: "#757575"}
} as const;

type Props = ComponentPropsWithoutRef<"div"> & {
  status: RankingContentStatus;
};

const RankingContentStatusChip = ({
  status
}: Props): JSX.Element => {
  return (
    <StyledChip status={status}>
      {RankingContentStatusLabels[status]}
    </StyledChip>
  );
};

const StyledChip = styled("div")<{
  status: RankingContentStatus
}>(({ status, theme }) => ({
  backgroundColor: ColorsByStatus[status].background,
  display: "flex",
  color: ColorsByStatus[status].text,
  padding: theme.spacing(0.5, 1),
  border: 0,
  fontWeight: "bold",
  fontSize: 14,
  whiteSpace: "nowrap",
  justifyContent: "center",
  alignItems: "center"
}));

export default RankingContentStatusChip;
